import { store } from "../store/createdStore";
import { setErrorValue } from "../store/slices/errorsSlice";
import { getFiltersData, setFiltersDataLoaded } from "../store/slices/filterDataSlice";
import { getRequest } from "./transport";

export const new_getFilterData = (id) => {
  //генератор id
  let numbers = [];

  function generator() {
    for (let i = 1; i < 1000; i++) {
      numbers.push(i);
    }
    return;
  }
  generator();

  //ABC
  const abc = ["A", "B", "C"];
  let obj = [];

  abc.forEach((el, idx) => {
    obj.push({
      value: idx + 1,
      label: el,
    });
  });
  store.dispatch(getFiltersData({ name: "new_abc", value: obj }));

  //статусы
  const statuses = ["В пути", "Отмена заказа", "Получен"];
  let arr = [];

  statuses.forEach((el, i) => {
    arr.push({
      value: i + 1,
      label: el,
    });
  });
  store.dispatch(getFiltersData({ name: "new_statuses", value: arr }));

  //запрос нового списка брендов
  getRequest(`ia/wb/available_brands/?wb_data=${id}`)
    .then((res) => {
      let obj = [];
      res.result.brands.forEach((el, idx) => {
        obj.push({
          value: idx + 1,
          label: el,
        });
      });
      store.dispatch(getFiltersData({ name: "new_brands", value: obj }));
    })
    .catch((err) => {
      err.message.includes(403) && store.dispatch(setErrorValue({ name: "noAccess", value: true }));
      err.message.includes(503) && store.dispatch(setErrorValue({ name: "serviceErrorIA", value: true }));
    });

  //запрос новых категорий
  getRequest(`ia/wb/available_categories/?wb_data=${id}`)
    .then((res) => {
      let obj = [];

      for (let key in res.result.categories) {
        obj.push({
          label: key,
          value: +numbers.splice(0, 1).join(""),
          children: res.result.categories[key].map((item) => ({
            value: +numbers.splice(0, 1).join(""),
            label: item,
          })),
        });
      }
      return obj;
    })
    .then((obj) => {
      let newObj = [];

      obj.map((el) => {
        newObj.push({
          label: el.label,
          value: el.value,
          children: el.children.map((item) => ({
            value: item.value,
            label: item.label,
            parentKey: el.value,
          })),
        });
      });
      store.dispatch(getFiltersData({ name: "new_categories", value: newObj }));
    })
    .catch((err) => {
      err.message.includes(403) && store.dispatch(setErrorValue({ name: "noAccess", value: true }));
      err.message.includes(503) && store.dispatch(setErrorValue({ name: "serviceErrorIA", value: true }));
    });

  //запрос нового списка складов
  getRequest(`ia/wb/available_warehouses/?wb_data=${id}`)
    .then((res) => {
      let obj2 = [];

      for (let key of res.result.warehouses) {
        obj2.push({
          label: key,
          value: +numbers.splice(0, 1).join(""),
        });
      }
      store.dispatch(getFiltersData({ name: "new_warehouses", value: obj2 }));
    })
    .catch((err) => {
      err.message.includes(403) && store.dispatch(setErrorValue({ name: "noAccess", value: true }));
      err.message.includes(503) && store.dispatch(setErrorValue({ name: "serviceErrorIA", value: true }));
    });

  //запрос новых городов доставки
  getRequest(`ia/wb/available_oblast/?wb_data=${id}&last_month=true`)
    .then((res) => {
      let obj3 = [];

      for (let key of res.result.oblast) {
        obj3.push({
          label: key,
          value: +numbers.splice(0, 1).join(""),
        });
      }
      store.dispatch(getFiltersData({ name: "new_oblast", value: obj3 }));
    })
    .then(() => store.dispatch(setFiltersDataLoaded()))
    .catch((err) => {
      err.message.includes(403) && store.dispatch(setErrorValue({ name: "noAccess", value: true }));
      err.message.includes(503) && store.dispatch(setErrorValue({ name: "serviceErrorIA", value: true }));
    });
};
