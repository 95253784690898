import React, { useEffect, useState } from "react";
import useDebounce from "../../../../utils/useDebounce";
import { postRequest } from "../../../../requestor/transport";
import { useDispatch, useSelector } from "react-redux";
import strings from "../lang/labels_ru.json";
import { SpinnerLoader } from "../../../typography/SpinnerLoader";
import { setEvent } from "../../../../store/slices/createEventSlice";

const Products = ({ requestMark }) => {
  const state = useSelector((state) => state.createEvent);
  const web_source = useSelector((state) => state.createEvent.web_source);
  const { product, error, addMore, clearInputs, vendor_code } = state;
  const dispatch = useDispatch();
  const [local, setLocal] = useState({
    text: "",
    loading: false,
    result: "nothing",
    focus: false,
  });
  const { result, loading, text } = local;
  const debounced = useDebounce(text, 100);
  useEffect(() => {
    if (error.product) {
      dispatch(setEvent({ name: "error", value: { ...error, product: false, vendor_code: false } }));
    }
    dispatch(setEvent({ name: "product", value: {} }));
    setLocal((state) => ({ ...state, result: "nothing" }));
    if (text.length > 0) {
      setLocal((state) => ({ ...state, loading: true, result: "nothing" }));
    }
  }, [text, web_source]);

  useEffect(() => {
    if (addMore) {
      setLocal((state) => ({ ...state, text: "", loading: false, result: "nothing", focus: false }));
    }
  }, [addMore, clearInputs]);

  useEffect(() => {
    if (debounced.length > 0 && web_source.value) {
      dispatch(setEvent({ name: "vendor_code", value: debounced }));
      postRequest("events/check_product/", {
        vendor_code: debounced,
        web_source: web_source.value,
      })
        .then((res) => {
          if (res === "no result") {
            setLocal((state) => ({ ...state, result: "no product" }));
          } else {
            setLocal((state) => ({ ...state, result: "product" }));
            dispatch(setEvent({ name: "product", value: res }));
            requestMark && requestMark("create_vendor_code");
          }
        })
        .then(() => setLocal((state) => ({ ...state, loading: false })))
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLocal((state) => ({ ...state, result: "nothing" }));
      dispatch(setEvent({ name: "product", value: {} }));
      dispatch(setEvent({ name: "vendor_code", value: "" }));
    }
  }, [debounced, web_source]);
  return (
    <div className="createEvent__product">
      <div
        className={`createEvent__brands__row 
                ${result === "no product" || error.vendor_code ? "-error" : ""}
                ${local.focus ? "-focus" : ""}
                `}
      >
        <input
          type="text"
          onFocus={() => setLocal((state) => ({ ...state, focus: true }))}
          onBlur={() => setLocal((state) => ({ ...state, focus: false }))}
          className="createEvent__brands__input"
          onChange={(e) => {
            e.persist();
            setLocal((state) => ({ ...state, text: e.target.value }));
          }}
          value={text}
          onClick={(e) => e.target.select()}
        />
        {loading && (
          <div className="createEvent__product__loader">
            <SpinnerLoader />
          </div>
        )}
        {text.length > 0 && !loading && (
          <i className="icon-close" onClick={() => setLocal((state) => ({ ...state, text: "" }))} />
        )}
      </div>

      {!loading && (
        <div className="createEvent__product__info">
          {result === "product" && product.name && vendor_code && (
            <>
              <div className="createEvent__product__info__image">
                <img src={product.main_pic} alt="product" />
              </div>
              <div className="name__product__name">
                <p className="name__product__breadcrumbs">
                  {product.brand} / {product.category}
                </p>
                <p className="name__product__label">{product.name}</p>
              </div>
            </>
          )}
          {(result === "no product" || (result !== "no product" && state.error.product)) && (
            <span className="createEvent__product__info__error">
              {result === "no product" && strings.not_found}
              {result !== "no product" && state.error.product && strings.product_error}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export { Products };
