import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: sessionStorage.getItem("tab") ? JSON.parse(sessionStorage.getItem("tab")) : {},
  select: {},
};

export const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setTab: (state, action) => {
      sessionStorage.setItem("tab", JSON.stringify(action.payload));
      state.tab = action.payload;
    },
    setTabSelect: (state, action) => {
      state.select = action.payload;
    },
  },
});

export const { setTab, setTabSelect } = tabsSlice.actions;

export default tabsSlice.reducer;
