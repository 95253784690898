import { createSlice } from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";
import dayjs from "dayjs";
import { getValueFromCookie } from "../../utils/getValueFromCookie";

const token = getValueFromCookie("token");

const initialState =
  token && localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : { page: JSON.parse(localStorage.getItem("page")) ?? null };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      const { access_token, expires_in, refresh_token, ...user } = action.payload;
      document.cookie = `token=${access_token}; max-age=${expires_in}; path=/;`;
      document.cookie = `refresh_token=${refresh_token}; path=/`;
      localStorage.setItem("user", JSON.stringify(user));
      const now = dayjs(new Date());
      const estimateTime = dayjs(now).add(9, "minute");
      localStorage.setItem("estimate_time", JSON.stringify(estimateTime));
      return (state = user);
    },
    userSidebar: (state, action) => {
      const sidebarState = { ...state, sidebar: action.payload };
      localStorage.setItem("user", JSON.stringify(sidebarState));
      state.sidebar = action.payload;
    },
    userPage: (state, action) => {
      localStorage.setItem("page", JSON.stringify(action.payload));
      state.page = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem("user");
      localStorage.clear();
      sessionStorage.clear();
      document.cookie = "token=; max-age=0; path=/;";
      document.cookie = "refresh_token=; max-age=0; path=/;";
      return (state = {});
    },
    userRefresh: (state, action) => {
      const { user } = action.payload;
      const newUser = { ...state, user: { ...state.user, ...user } };
      localStorage.setItem("user", JSON.stringify(newUser));
      return (state = newUser);
    },
    userSetBoarding: (state, action) => {
      state.user.boarding_viewed = action.payload;
      localStorage.setItem("user", JSON.stringify(state));
    },
    userViewed: (state, action) => {
      state.user.extra_json = { ...state.user.extra_json, ...action.payload };
      localStorage.setItem("user", JSON.stringify(state));
    },
    authError: (state, action) => {
      state.error = action.payload;
    },

    setModalInterviewViewed: (state, action) => {
      const { modal_interview_viewed } = action.payload;
      state.user.modal_interview_viewed = modal_interview_viewed;
      localStorage.setItem("user", JSON.stringify(state));
    }
  },
  extraReducers: {
    [LOCATION_CHANGE]: (state) => {
      state.error = "";
    },
  },
});

export const { login, userSidebar, userPage, logout, userRefresh, userSetBoarding, userViewed, authError, setModalInterviewViewed } = authSlice.actions;

export default authSlice.reducer;
