import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  seoArticlesPlus: localStorage.getItem("seoArticlesPlus") ? JSON.parse(localStorage.getItem("seoArticlesPlus")) : [],
  seoArticlesMinus: localStorage.getItem("seoArticlesMinus") ? JSON.parse(localStorage.getItem("seoArticlesMinus")) : [],
  seoKeywords: localStorage.getItem("seoKeywords") ? JSON.parse(localStorage.getItem("seoKeywords")) : [],
  seoWorkingKeywords: localStorage.getItem("seoWorkingKeywords") ? JSON.parse(localStorage.getItem("seoWorkingKeywords")) : [],
  seoMinusKeywords: localStorage.getItem("seoMinusKeywords") ? JSON.parse(localStorage.getItem("seoMinusKeywords")) : [],
};

export const seoOptimizationSlice = createSlice({
  name: "seoOptimization",
  initialState,
  reducers: {
    setSeoArticles: (state, action) => {
      const { name, value } = action.payload;
      localStorage.setItem(name, JSON.stringify(value));
      state[name] = value;
    },
  },
});

export const { setSeoArticles } = seoOptimizationSlice.actions;

export default seoOptimizationSlice.reducer;
