import React, { useEffect } from 'react'
import closeBtn from 'assets/images/demo/close.png'
import question from 'assets/images/demo/question.png'

export default function RequestReminder({ onChange, setReminderStep, setShow, setLight }) {
  useEffect(() => {
    setShow(true)
    setLight(true)
  }, [])

  return (
    <>
      <div className='card__wrapper card__wrapper--demo'>
        <div className='card__layout'>
          <div className='demoRequest'>
            <div className='demoRequest__modal -reminder'>
              <div className='skipTimer -close -min'>
                <img src={closeBtn} width='10' height='10'
                  alt="pic"
                  onClick={() => {
                    setReminderStep(false)
                    setShow(false)
                    setLight(false)
                    onChange()
                  }}
                />
              </div>

              <div className='_img' >
                <img src={question} width='64' height='64' alt="img" />
              </div>

              <div className='demoRequest__modal__body'>
                <div className='center'>
                  <div className='paragraph -common'>
                    <p>
                      Если Вы хотите запросить демонстрацию сервиса позже, то можете сделать это в боковой панели
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

