import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import commentImg from "assets/images/icons/comment-white.svg";
import { Button, Toggle } from "@neurodynamics/mv-ui";
import { requestMark } from "utils/requestMark";
import { postRequest, putRequest } from "requestor/transport";
import { userViewed } from "store/slices/authSlice";

const ServiceEstimateModal = ({ setShowEstimateModal, setThanksModal }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [timer, setTimer] = useState(5);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [text, setText] = useState("");
  const [isDisplay, setIsDisplay] = useState(false);
  const [comment, setComment] = useState("");
  const [activeToggle, setActiveToggle] = useState(true);

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const handleSendEstimate = () => {
    setShowEstimateModal(false);
    localStorage.setItem('estimate_time', JSON.stringify(null));
    postRequest("services/review/", { grade: rating, comment, aggree_post: activeToggle }).then((res) => {
      res.status === 201 && setThanksModal(() => true);
    });
    dispatch(userViewed({ service_estimate_viewed: true }));
    putRequest("users/user_extra/", { extra_json: { ...user.extra_json, service_estimate_viewed: true } });
  };

  const handleClose = () => {
    setShowEstimateModal(false);
    localStorage.setItem('estimate_time', JSON.stringify(null))
    dispatch(userViewed({ service_estimate_viewed: true }));
    putRequest("users/user_extra/", { extra_json: { ...user.extra_json, service_estimate_viewed: true } });
    // отправляем метку, только если пользователь не выставил оценку
    !rating && requestMark("close_service_estimate");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((timer) => timer - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (rating > 3) {
      setText(() => "Что вам больше всего понравилось");
      setIsDisplay(() => true);
    } else if (rating <= 3 && rating > 0) {
      setText(() => "Что вам не понравилось? Ваш комментарий позволит нам стать лучше");
      setIsDisplay(() => true);
    }
  }, [rating]);

  return (
    <div className="modal-wrapper">
      <div className="service-estimate__modal">
        {timer === 0 ? (
          <i className="icon-close" onClick={handleClose} />
        ) : (
          <div className="skipTimer">
            <div className="skipTimer -text">Закрыть через </div>
            <div className="skipTimer -sec">{timer} сек</div>
          </div>
        )}
        <div className="service-estimate__modal__content">
          <div className="service-estimate__modal__content__circle">
            <img src={commentImg} alt="image_comment" />
          </div>
          <h2>Как вам наш сервис?</h2>
          <div className="star-wrapper">
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <a
                  href="#"
                  key={index}
                  onClick={() => setRating(() => index)}
                  className={index <= (hover || rating) ? "fas fa-star" : "fa fa-star-o"}
                  onMouseEnter={() => setHover(() => index)}
                  onMouseLeave={() => setHover(() => rating)}
                ></a>
              );
            })}
          </div>
          {isDisplay && (
            <div className="service-estimate__modal__comment">
              <div className="service-estimate__modal__comment__text">{text}</div>
              <textarea
                autoFocus
                maxLength={500}
                value={comment}
                onInput={(e) => handleComment(e)}
                className="service-estimate__modal__comment__area"
              ></textarea>
              <div className="service-estimate__modal__comment__toggler">
                <Toggle onChange={() => setActiveToggle((prev) => !prev)} isActive={activeToggle} size={"s"} theme={"light"} />
                <p>Разрешаю опубликовать отзыв</p>
              </div>
              <div className="service-estimate__modal__comment__button">
                <Button label={"Отправить"} color={"blue"} onClick={handleSendEstimate} size={"m"} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceEstimateModal;
