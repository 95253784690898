import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loaded: localStorage.getItem("filtersLoaded") ? localStorage.getItem("filtersLoaded") : false,
  categories: sessionStorage.getItem("all_categories") ? JSON.parse(sessionStorage.getItem("all_categories")) : [],
  new_categories: localStorage.getItem("all_new_categories") ? JSON.parse(localStorage.getItem("all_new_categories")) : [],
  web_sources: localStorage.getItem("all_web_sources") ? JSON.parse(localStorage.getItem("all_web_sources")) : [],
  genders: localStorage.getItem("all_genders") ? JSON.parse(localStorage.getItem("all_genders")) : [],
  prices: localStorage.getItem("all_prices") ? JSON.parse(localStorage.getItem("all_prices")) : [],
  brands: localStorage.getItem("all_brands") ? JSON.parse(localStorage.getItem("all_brands")) : [],
  new_brands: localStorage.getItem("all_new_brands") ? JSON.parse(localStorage.getItem("all_new_brands")) : [],
  new_abc: localStorage.getItem("all_new_abc") ? JSON.parse(localStorage.getItem("all_new_abc")) : ["A", "B", "C"],
  new_warehouses: localStorage.getItem("all_new_warehouses") ? JSON.parse(localStorage.getItem("all_new_warehouses")) : [],
  xls_types: localStorage.getItem("all_xls_types") ? JSON.parse(localStorage.getItem("all_xls_types")) : [],
  new_statuses: localStorage.getItem("all_new_statuses") ? JSON.parse(localStorage.getItem("all_new_statuses")) : [],
  new_oblast: localStorage.getItem("all_new_oblast") ? JSON.parse(localStorage.getItem("all_new_oblast")) : [],
};

export const filterDataSlice = createSlice({
  name: "filterData",
  initialState,
  reducers: {
    setFiltersDataLoaded: (state) => {
      localStorage.setItem("filtersLoaded", true);
      state.loaded = true;
    },
    getFiltersData: (state, action) => {
      const { name, value } = action.payload;
      localStorage.setItem(`all_${name}`, JSON.stringify(value));
      state[name] = value;
    },
    getFiltersDataSession: (state, action) => {
      const { name, value } = action.payload;
      sessionStorage.setItem(`all_${name}`, JSON.stringify(value));
      state[name] = value;
    },
  },
});

export const { setFiltersDataLoaded, getFiltersData, getFiltersDataSession } = filterDataSlice.actions;

export default filterDataSlice.reducer;
