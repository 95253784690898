import { baseApi } from "requestor/api/baseApi";
import { setEventKey, setEventsKeyList, setKeyToList } from "store/slices/apikeySlice";

export const apikeysApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getApikeys: build.query({
      query: () => "ia/wb/data/",
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          let events_key_list = data
            .filter((el) => {
              if (el.is_api_key_valid && el.is_owner && el.is_ready) {
                return el;
              }
            })
            .map((el) => {
              return { value: el.id, label: el.description, key: el.api_key, is_active: true };
            });
          dispatch(setKeyToList(data));
          dispatch(setEventsKeyList(events_key_list));
          dispatch(setEventKey({ name: "update", value: true }));
        } catch (e) {
          console.error(e);
        }
      },
    }),
  }),
});

export const { useGetApikeysQuery } = apikeysApi;
