import React, { useEffect, useState } from "react";
import { Slider } from "@blueprintjs/core";
import { useSelector, useDispatch } from "react-redux";
import { setEvent } from "../../../../store/slices/createEventSlice";

const DotSlider = ({isEventExist}) => {
  const { internal_event_type } = useSelector((state) => state.createEvent);
  const [value, setValue] = useState(1);
  const [measurement, setMeasurement] = useState("%");
  const handleActive = () => (value > 0 ? "-plus" : value < 0 ? "-minus" : "");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setEvent({ name: "diff_value", value: value }));
  }, [value]);

  useEffect(() => {
    internal_event_type?.value == "STOCKS_EVENT" ? setMeasurement("дн.") : setMeasurement("%")
  }, [internal_event_type]);

  return (
    <>
      <div className={`createEvent__slider${!!isEventExist ? ' disabled' : ''}`}>
        <Slider
          min={1}
          max={100}
          intent={`${handleActive()}`}
          stepSize={1}
          className={handleActive()}
          labelRenderer={false}
          value={value}
          onChange={(v) => setValue(v)}
          disabled={!!isEventExist}
        />
      </div>
      <div className={`createEvent__slider__text ${handleActive()}`}>
        {value} {measurement}
      </div>
    </>
  );
};

export { DotSlider };
