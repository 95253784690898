import React, { useEffect } from "react";
import { getValueFromCookie } from "../../utils/getValueFromCookie";
import SideTemplate from "./templates/SideTemplate";
import MockTemplate from "./templates/MockTemplate";
import MockTemplateWB from "./templates/MockTemplateWB";
import { useSelector } from "react-redux";
import { socketConnect, ws } from "./websocket/websocket";

const Notification = () => {
  const token = getValueFromCookie("token");
  const strings = {
    download: "Скачать отчет",
    close: "Закрыть",
    pass: "Изменить пароль",
    payment: "Перейти к оплате",
  };

  const { user } = useSelector((state) => state.auth);
  const notificationStore = useSelector((state) => state.notification);

  useEffect(() => {
    // Получаем список нотификаций через подключение к сокету
    token && socketConnect();
  }, []);

  return (
    <>
      {/* Создаем нотификации отчетов (черные слева в углу) */}
      <div className="notification">
        {notificationStore.lastNotifications && (
          <div className="notification__block">
            {notificationStore.lastNotifications
              .filter(
                (item) =>
                  (item.type === "REPORT_STATUS" ||
                    (item.type === "TARGET" &&
                      (item.event_type === 1 || item.event_type === 2 || item.event_type === 3 || item.event_type === 4)) ||
                    item.type === "INFO" ||
                    item.type === "PRESET_SCHEDULER") &&
                  !item.is_read
              )
              .map((item, index) => {
                return (
                  <SideTemplate
                    key={index}
                    type={item.type}
                    event_type={item.event_type}
                    ws={ws}
                    title={item.title}
                    id={item.id}
                    email={user.email}
                    url_file={item.url_file}
                    description={item.description}
                    extra={item.event_result}
                    close={strings.close}
                    text={strings.download}
                  />
                );
              })}
          </div>
        )}
        {notificationStore.notification
          .filter((item) => item.notify_type === "REPORT_MOCK")
          .map((item) => {
            return (
              <MockTemplate
                key={item.id}
                title={item.title}
                description={item.description}
                error={item.error}
                attention={item.attention}
                report={item.report}
                id={item.id}
                lang={user.lang}
              />
            );
          })}
        {notificationStore.notification
          .filter((item) => item.notify_type === "REPORT_MOCK_WB")
          .map((item) => {
            return (
              <MockTemplateWB
                key={item.id}
                title={item.title}
                description={item.description}
                error={item.error}
                id={item.id}
                lang={user.lang}
              />
            );
          })}
      </div>
    </>
  );
};

export default Notification;
