import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import Center from "../notification/center/Center";
import ApikeyList from "./ApikeyList";
import { FaqWidget } from "./FaqWidget";
import UserWidget from "./UserWidget";
import logo from "assets/images/logo-small.svg";
import textlogo from "assets/images/logo-text.svg";
import img_WB from "assets/images/icons/img_WB.svg";
import img_ozon from "assets/images/icons/ozon_logo.svg";
import img_lamoda from "assets/images/icons/lamoda_logo.svg";
import errorImg from "assets/images/icons/error.svg";
import { userRefresh, userSidebar } from "../../store/slices/authSlice";
import { setInternalId } from "../../store/slices/apikeySlice";
import { setDataCounter, setDataCounterType, setLoadingData } from "../../store/slices/loadingSlice";
import { changeWebSource, setSingleInside } from "../../store/slices/filterSlice";
import dayjs from "dayjs";
import axios from "axios";
import { Button } from "@neurodynamics/mv-ui";
import { getValueFromCookie } from "../../utils/getValueFromCookie";
import { ModalDemoInternal } from "./ModalDemoInternal";
import { store } from "store/createdStore";
import { getRequest } from "requestor/transport";

const Sidebar = () => {
  const { sidebar, user } = useSelector((state) => state.auth);
  const { is_recurrent } = user;
  const [menuShow, setMenuShow] = useState(!!sidebar);
  const [internalDemoShow, setInternalDemoShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { serviceErrorCommon } = useSelector((state) => state.errors);
  const { web_sources } = useSelector((state) => state.filterData);

  const today = dayjs();
  const demoDateEnd = dayjs(user.date_end).subtract(36497, "day");
  const diffHours = dayjs(user.tariff_type === "demo" ? demoDateEnd : user.date_end).diff(today, "hour");
  const diffMinutes = dayjs(user.tariff_type === "demo" ? demoDateEnd : user.date_end).diff(today, "minute");

  const sourcesImg = {
    WildBerries: img_WB,
    Lamoda: img_lamoda,
    Ozon: img_ozon,
  };

  const handleCloseInternalDemo = () => {
    setInternalDemoShow(false);
  };

  const handleOpenInternalDemo = () => {
    setInternalDemoShow(true);
  };

  const updateHandleToggle = (show) => {
    setMenuShow(show);
    dispatch(userSidebar(show));
  };

  const handleToggle = async () => {
    if (!serviceErrorCommon) {
      const show = menuShow;

      if (show) {
        try {
          await axios.get(`${process.env.REACT_APP_HOST}services/event-log/?event=close_sidebar`);
        } catch (err) {
          console.log(err);
        }
      }

      setMenuShow(!show);
      dispatch(userSidebar(!show));
    }
  };

  const handleLinkClick = async (id, label) => {
    dispatch(setInternalId(""));
    dispatch(changeWebSource(id));

    const pageNames = ["brandsPage", "suppliersPage", "categoryPage"];
    pageNames.forEach((pageName) => {
      dispatch(setSingleInside({ name: "category", value: "", pageName}));
      dispatch(setSingleInside({ name: "sub_category", value: "", pageName }));
      dispatch(setSingleInside({ name: "price_lte", value: "", pageName }));
      dispatch(setSingleInside({ name: "price_gte", value: "", pageName }));
      dispatch(setSingleInside({ name: "category_keys", value: [], pageName }));
      dispatch(setSingleInside({ name: "subjects", value: [], pageName }));
      dispatch(setSingleInside({ name: "pageItemId", value: "", pageName }));
      dispatch(setSingleInside({ name: "delivery_date", value: {}, pageName }));
    })
    dispatch(setSingleInside({ name: "brands", value: [] }));
    dispatch(
      setSingleInside({
        name: "whs_type",
        value: {
          value: 1,
          label: "FBO",
          is_active: true,
        },
      })
    );

    dispatch(setLoadingData(false));
    dispatch(setDataCounter(0));
    dispatch(setDataCounterType(""));

    if (label) {
      if (menuShow) {
        try {
          await axios.get(`${process.env.REACT_APP_HOST}services/event-log/?event=${label}_with_opened_sidebar`);
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          await axios.get(`${process.env.REACT_APP_HOST}services/event-log/?event=${label}_with_closed_sidebar`);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const isDemo = user.tariff_type === "demo";

  const convertTariffName = () => {
    let tariffs = [
      { name: "Demo", type: "demo" },
      { name: "Внутренняя", type: "ia" },
      { name: "Внешняя", type: "ea" },
      { name: "Профи", type: "pro" },
    ];
    let tariff = tariffs.find((el) => el.type === user.tariff_type);
    return tariff.name;
  };

  const requestMark = async () => {
    const token = getValueFromCookie("token");
    try {
      await axios.get(`${process.env.REACT_APP_HOST}services/event-log/?event=upgrade_tariff_from_demo`, token && {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const goToTariffs = () => {
    navigate("/payment");
    requestMark();
  };

  useEffect(() => {
    if (sidebar !== undefined) {
      updateHandleToggle(sidebar);
    } else {
      updateHandleToggle(true);
    }
  }, [sidebar, user]);

  useEffect(() => {
    if (user) {
      getRequest(`users/entitlements/`).then((res) => {
        if (res.web && Object.keys(res.web)?.length > 0) {
          store.dispatch(
            userRefresh({
              user: {
                tariff_name: res.web.tariff_name,
                tariff_type: res.web.tariff_type,
                is_recurrent: res.web.is_recurrent,
                date_end: res.web.date_end,
              },
            })
          );
        }
      });
    
      setInterval(() => {
    getRequest(`users/entitlements/`).then((res) => {
      if (res.web && Object.keys(res.web)?.length > 0) {
        store.dispatch(
          userRefresh({
            user: {
              tariff_name: res.web.tariff_name,
              tariff_type: res.web.tariff_type,
              is_recurrent: res.web.is_recurrent,
              date_end: res.web.date_end,
            },
          })
        );
      }
    });
  }, 3 * 60 * 1000)
  }
}, [])

  return (
    <div className="sidebar">
      <div className="sidebar__panel">
        <div className="sidebar__logo">
          <NavLink to="/">
            <img src={logo} alt="logo" />
          </NavLink>
        </div>
        <div className="sidebar__icons">
          <i className="icon-arrows-out" onClick={() => handleToggle()} />
          {web_sources?.length > 0 &&
            web_sources.map((item, i) => {
              return item.is_active ? (
                <NavLink to={`/dashboard/${item.value}`} key={i} onClick={() => handleLinkClick(item.value, item.label)}>
                  <img className="img_logo" src={sourcesImg[item.label]} alt="img_logo" />
                </NavLink>
              ) : (
                <img key={item.value} className="img_logo" src={sourcesImg[item.label]} alt="img_source_logo" />
              );
            })}
          <NavLink to="/events/" onClick={() => handleLinkClick(1, "events")}>
            <i className="icon-target img_logo_events" />
          </NavLink>
          <i className="icon-arrows-in" onClick={() => handleToggle()} />
        </div>
        <div className="sidebar__info">
          <Center />
          <FaqWidget />
          <UserWidget />
        </div>
      </div>
      <div className={`sidebar__content ${menuShow && !serviceErrorCommon ? "-active" : "-hidden"}`}>
        <div>
          <div className="sidebar__title">
            <NavLink to="/">
              <img alt="Market vision" src={textlogo} />
            </NavLink>
          </div>
          <div className="sidebar__body">
            <nav className="sidebar__menu">
              <ul>
                <li className="sidebar__chapter">Внешняя аналитика</li>
              </ul>
              <ul className="sidebar__chapter__list-sidebar">
                {web_sources?.length > 0 &&
                  web_sources.map((item) => {
                    return item.is_active ? (
                      <NavLink
                        key={item.value}
                        to={`/dashboard/${item.value}`}
                        className={({ isActive }) => (isActive ? "-active" : undefined)}
                        onClick={() => handleLinkClick(item.value, item.label)}
                      >
                        <li className="sidebar__chapter__list-item">
                          <div className="block">
                            <div className="text">
                              <div className="descr">{item.label}</div>
                            </div>
                          </div>
                        </li>
                      </NavLink>
                    ) : (
                      <li key={item.value} className="sidebar__chapter__list-item" style={{ cursor: "default" }}>
                        <div className="block">
                          <div className="text">
                            <div className="descr grey">{item.label}</div>
                            <div className="soon">Скоро</div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
              <div className="grey-line"></div>
              <ul className="sidebar__chapter__list-sidebar">
                <NavLink
                  to="/events/"
                  className={({ isActive }) => (isActive ? "-active" : "")}
                  onClick={() => handleLinkClick(1, "events")}
                >
                  <li className="sidebar__chapter__list-item" onClick={() => dispatch(setInternalId(""))}>
                    <div className="block">
                      <div className="text">
                        <div className="descr">События</div>
                      </div>
                    </div>
                  </li>
                </NavLink>
              </ul>
              <ul>
                <li className="sidebar__chapter">Внутренняя аналитика</li>
                <ApikeyList />
              </ul>
            </nav>
          </div>
          <div className="sidebar__add-api">
              {isDemo && (
                <button onClick={handleOpenInternalDemo} className="sidebar__button-internal-demo">Как работает внутренняя</button>
              )}
              <NavLink
                to="/apikey/"
                onClick={async () => {
                  try {
                    await axios.get(`${process.env.REACT_APP_HOST}services/event-log/?event=add_api_btn`);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                <span>+</span>Добавить API
              </NavLink>
            </div>
        </div>
        <div className="sidebar__bottom">
          {user.tariff_type && (
            <div className="sidebar__tariff">
              <div className="sidebar__tariff-top">
                <div>Тариф</div>
                <div className={`sidebar__tariff-name ${user.tariff_type}`}>{convertTariffName()}</div>
              </div>
              <div className="sidebar__tariff-bottom">
                <div className={`sidebar__tariff-desc -${user.tariff_type}`}>
                  {isDemo && (
                    <>
                      <img src={errorImg} alt="error" />
                      <div>Тариф имеет ограничения</div>
                    </>
                  )}
                  {!isDemo && diffHours >= 0 && diffMinutes >= 0 ? (
                    <>
                      <div className="sidebar__tariff-desc text">{is_recurrent ? "Cледующее списание" : "Действует до"}</div>&nbsp;
                      <div>{dayjs(user.date_end).locale("ru").format("DD.MM.YY")}</div>
                    </>
                  ) : (
                    !isDemo &&
                    diffMinutes < 0 && (
                      <>
                        <img src={errorImg} alt="error" />
                        <div style={{ marginBottom: "10px" }}>Срок действия тарифа истёк</div>
                      </>
                    )
                  )}
                </div>
                {!isDemo && diffHours <= 0 && diffMinutes < 0 && (
                  <Button label={"Продлить тариф"} color={"blue"} size={"m"} onClick={() => goToTariffs()} />
                )}
                {isDemo && <Button label={"Улучшить тариф за 1 990 ₽"} color={"blue"} size={"m"} onClick={() => goToTariffs()} />}
              </div>
            </div>
          )}
          <div className="sidebar__version">Version software: {process.env.REACT_APP_VERSION}</div>
        </div>
      </div>
      <button className={`sidebar__toggle ${menuShow ? "-active" : "-hidden"}`} onClick={() => handleToggle()}>
        <i className="icon-angle" />
      </button>
      {internalDemoShow && <ModalDemoInternal onChange={handleCloseInternalDemo} isDisplay={true} />}
    </div>
  );
};

export default Sidebar;
