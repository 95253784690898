import React, { useEffect, useRef, useState } from "react";
import strings from "./lang/ru.json";
import { useDispatch, useSelector } from "react-redux";
import img from "assets/images/center_empty.svg";
import { getValueFromCookie } from "../../../utils/getValueFromCookie";
import * as dayjs from "dayjs";
import "dayjs/locale/ru";
import relativeTime from "dayjs/plugin/relativeTime";
import { setNotificationsThunk } from "../../../store/slices/notificationSlice";
import { ws } from "../websocket/websocket";
import axios from "axios";
dayjs.extend(relativeTime);

const Center = () => {
  const token = getValueFromCookie("token");
  const notificationStore = useSelector((state) => state.notification);
  const socket = ws;
  const events = useSelector((state) => state.events);
  const apiKey = useSelector((state) => state.apiKey);
  const node = useRef();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [notification, setNotification] = useState(notificationStore.notification && notificationStore.notification);
  let showEarly = false;

  const handleBtnClick = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_HOST}services/event-log/?event=notification_btn`);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getNotification();
  }, []);

  useEffect(() => {
    if (isVisible) {
      document.addEventListener("mousedown", handleClick);
    } else {
      document.removeEventListener("mousedown", handleClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [isVisible]);

  useEffect(() => {
    if (isVisible) {
      getNotification();
      dispatch(setNotificationsThunk(token))
    }
  }, [isVisible]);

  const getNotification = async () => {
    try {

    const res = await dispatch(setNotificationsThunk(token));

    const centerNotifications = res.payload.results.filter(
      (item) =>
        (item.type === "TARGET" && (item.event_type === 1 || 2 || 3 || 4)) ||
        item.type === "REPORT_STATUS" ||
        item.type === "INFO" ||
        item.type === "PRESET_SCHEDULER"
    )
    setNotification(centerNotifications);
    setLoaded(true);

} catch (err) {
    console.log(err);
  }
  };

  const changeInfoTitle = (item) => {
    if (item.type === "INFO") {
      const str = item.description.replace(/"/g, "");
      let newStr = str.split(item.title);
      return [newStr[0], <b>{item.title}</b>, newStr[1]];
    }
  };

  // Method for closing window
  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setIsVisible(false);
  };

  return (
    <div className="notification-center" ref={node}>
      <button
        type="button"
        className={isVisible ? "-is-active" : undefined}
        onClick={() => {
          setIsVisible((v) => !v);
          socket?.send && socket.send(JSON.stringify({ type: "all_is_viewed" }));
          handleBtnClick()
        }}
      >
        <i className="icon-bell" />
        {notificationStore.not_read_count > 0 && <span>{notificationStore.not_read_count}</span>}
      </button>
      {isVisible && loaded && (
        <div className="notification-center__panel">
          <div className="notification-center__panel__header">
            <h3>{strings.title}</h3>
            {notification.length > 0 && (
              <a
                onClick={() => {
                  if (notification.filter((i) => i.is_read === false).length > 0) {
                    socket?.send && socket.send(JSON.stringify({ type: "all_is_read" }));
                  } else {
                    socket?.send && socket.send(JSON.stringify({ type: "all_is_deleted" }));
                  }
                  getNotification();
                }}
              >
                {notification.filter((i) => !i.is_read).length > 0 ? strings.titleLink : strings.titleLink2}
              </a>
            )}
          </div>

          <div className="notification-center__panel__body">
            {notification[0] &&
              dayjs().format("D") === dayjs(notification[0].datetime_created).locale("ru").format("D") &&
              dayjs().format("M") === dayjs(notification[0].datetime_created).locale("ru").format("M") &&
              dayjs().format("YYYY") === dayjs(notification[0].datetime_created).locale("ru").format("YYYY") && (
                <div className="notification-center__panel__delimeter">{strings.today}</div>
              )}
            {notification.length > 0 ? (
              notification.map((item) => {
                return (
                  <div key={item.id}>
                    {dayjs().locale("ru").format("DD-MM-YYYY") !==
                      dayjs(item.datetime_created).locale("ru").format("DD-MM-YYYY") &&
                      !showEarly && (
                        <>
                          {(showEarly = true)}
                          <div className="notification-center__panel__delimeter">{strings.old}</div>
                        </>
                      )}
                    <div
                      className={`notification-center__item -${item.type}`}
                      onClick={() => {
                        socket?.send &&
                          socket.send(
                            JSON.stringify({
                              id: item.id,
                              type: "is_readed",
                            })
                          );
                        getNotification();
                      }}
                    >
                      <div className="notification-center__item__icon">
                        {item.event_result &&
                        item.event_result.event &&
                        item.event_result.event.product_info &&
                        item.event_result.event.product_info.main_pic ? (
                          <img src={item.event_result.event.product_info.main_pic} alt="main_pic" />
                        ) : (
                          <i
                            className={`icon-${
                              item.type === "TARGET"
                                ? "target"
                                : item.type === "INFO"
                                ? "success"
                                : item.type === "PRESET_SCHEDULER"
                                ? "table-time"
                                : "table"
                            }`}
                          />
                        )}
                      </div>
                      <div className="notification-center__item__content">
                        <div className="notification-center__item__head">
                          {item.event_result &&
                          item.event_result.event &&
                          item.event_result.event.product_info &&
                          item.event_result.event.product_info.link ? (
                            <a href={item.event_result.event.product_info.link} target="_self">
                              {item.title}&nbsp;
                              {item.event_result && item.event_result.event && (
                                <>
                                  <b>{item.event_result.event.name}</b>
                                  <br />
                                  {item.event_result.event.web_source}
                                </>
                              )}
                            </a>
                          ) : (
                            <p>
                              {item.type !== "INFO" ? item.title : changeInfoTitle(item)}
                              &nbsp;
                              {item.event_result && item.event_result.event && item.event_type == 1 ? (
                                <>
                                  <b>{item.event_result.event.name}</b>
                                  <br />
                                  {item.event_result.event.web_source}
                                </>
                              ) : item.event_result && item.event_result.event && (item.event_type == 2 || 3 || 4) ? (
                                <>
                                  <b>
                                    {events.internal_event_types
                                      .find((el) => el.value == item.event_result.event.event_type)
                                      .label.toLowerCase()}
                                    &nbsp;
                                  </b>
                                  для ключа &nbsp;
                                  <b>
                                    "{apiKey.keys_list_for_events.find((el) => el.value == item.event_result.event.wb_data).label}
                                    "
                                  </b>
                                  <br />
                                  WildBerries
                                </>
                              ) : null}
                            </p>
                          )}
                          {!item.is_read && <div className="notification-center__item__unread" />}
                        </div>
                        {item.type === "TARGET" && item.event_type == 1 && (
                          <div
                            className={`notification-center__item__body ${
                              item.event_result?.diff_value ? (item.event_result.diff_value > 0 ? "-green" : "-red") : ""
                            }`}
                          >
                            {item.event_result?.diff_value && item.event_result?.value_new && item.event_result?.value_old
                              ? item.event_result.diff_value +
                                " %" +
                                " / " +
                                (item.event_result.value_new - item.event_result.value_old).toFixed(0) +
                                " ₽"
                              : ""}
                          </div>
                        )}
                        {item.type === "TARGET" && (item.event_type == 2 || 3 || 4) && (
                          <div className="notification-center__item__body">{item.description}</div>
                        )}
                        <div className="notification-center__item__footer">
                          {item.type === "TARGET" &&
                          item.event_result?.value_old &&
                          item.event_result?.value_new &&
                          item.event_type == 1 ? (
                            <span>
                              {strings.was} {item.event_result.value_old} ₽, {strings.now} {item.event_result.value_new} ₽
                            </span>
                          ) : item.type === "TARGET" && (item.event_type == 2 || 3 || 4) ? (
                            <span></span>
                          ) : null}
                          {(item.type === "REPORT_STATUS" || item.type === "PRESET_SCHEDULER") && (
                            <a
                              href={item.url_file}
                              onClick={(e) => {
                                let el = e.target;
                                el.classList.add("-is-downloaded");
                                el.innerHTML = strings.downloadReport2;
                                setTimeout(() => {
                                  el.classList.remove("-is-downloaded");
                                  el.innerHTML = strings.downloadReport;
                                }, 10000);
                              }}
                            >
                              {strings.downloadReport}
                            </a>
                          )}
                          <span>
                            {dayjs().locale("ru").format("DD-MM-YYYY") ===
                            dayjs(item.datetime_created).locale("ru").format("DD-MM-YYYY")
                              ? dayjs(item.datetime_created).locale("ru").fromNow()
                              : dayjs(item.datetime_created).locale("ru").format("D MMMM")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="notification-center__empty">
                <img src={img} alt="notification_center" />
                <p>{strings.empty1}</p>
                <span>{strings.empty2}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Center;
