import React, { useState, useEffect } from "react";
import presentation from "assets/images/demo/presentation.png";
import closeBtn from "assets/images/demo/close.png";
import Button from "../../buttons/Button";
import { getValueFromCookie } from "../../../utils/getValueFromCookie";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import MaskedInput from "react-text-mask";

export default function RequestWelcome({ isDisplay, handleClose, setSentStep, sendStatus, setIsSentSuccess }) {
  const [timer, setTimer] = useState(8); // 5 sec (delay 3 sec)
  const [delay, setDelay] = useState(false); // задержка появления окна
  const [active, setActive] = useState(false);

  const phoneNumberMask = ["+", "7", "(", /\d/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /[\d]+/, /[\d]+/, /[\d]+/, /[\d]+/];

  useEffect(() => {
    let delayFunc;

    if (!isDisplay) {
      // автоматическое появление окна после регистрации, при авторизации
      setDelay(true);

      delayFunc = setTimeout(() => {
        setDelay(false);
      }, 420000);
    }

    return () => clearTimeout(delayFunc);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setActive(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  return (
    <>
      {!delay && (
        <div className="card__wrapper card__wrapper--demo">
          <div className="card__layout">
            <div className="demoRequest">
              <div className="demoRequest__modal">
                {isDisplay ? (
                  <div className="skipTimer -close">
                    <img src={closeBtn} width="10" height="10" onClick={() => handleClose()} alt="skip_timer" />
                  </div>
                ) : timer > 0 ? (
                  <div className="skipTimer">
                    <div className="skipTimer -text">Закрыть через </div>
                    <div className="skipTimer -sec">{timer} сек</div>
                  </div>
                ) : (
                  <div className="skipTimer -close">
                    <img src={closeBtn} width="10" height="10" onClick={() => handleClose()}alt="close_timer" />
                  </div>
                )}
                <div className="_img">
                  <img src={presentation} alt="presentation" />
                </div>
                <Formik
                  initialValues={{
                    phone: "",
                    text: "",
                  }}
                  onSubmit={(fields) => {
                    const { phone, text } = fields;

                    const handleSubmit = async () => {
                      const token = getValueFromCookie("token");
                      const cancelToken = axios.CancelToken.source();
                      try {
                        const res = await axios.post(
                          `${process.env.REACT_APP_HOST}users/request_demo/`,
                          { phone: phone.replace(/\D/g, ""), request_message: text },
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                            cancelToken: cancelToken.token,
                          }
                        );

                        return res;
                      } catch (err) {
                        console.log(err.message);
                      }
                    };
                    handleSubmit().then((res) => {
                      setSentStep(true);
                      if (setIsSentSuccess) {
                        setIsSentSuccess(true);
                      }
                      sendStatus(); // отправим статус просмотра на сервер
                    });
                  }}
                  render={(formik) => (
                    <Form>
                      <div className="demoRequest__modal__body">
                        <div className="center">
                          <div className="paragraph -bold">Хотите больше узнать о возможностях сервиса?</div>
                          <div className="paragraph -common">
                            Наш менеджер подробно расскажет Вам о возможностях сервиса в формате онлайн-трансляции и ответит на
                            все интересующие вопросы
                          </div>
                        </div>
                        <div className="textBlock">Введите номер телефона для связи с Вами</div>
                        <Field
                          name="phone"
                          render={({ field }) => <MaskedInput {...field} mask={phoneNumberMask} id="phone" type="text" placeholder="+7" />}
                        />
                        <div className="textBlock -mt12">Опишите какие задачи Вы ставите перед сервисом</div>
                        <Field name="text" component="textarea" rows="4" />
                      </div>
                      <div className="demoRequest__modal__footer">
                        <div
                          className={isDisplay || active ? "button -text -gray" : "button -text -light-gray"}
                          onClick={isDisplay || active ? handleClose : null}
                        >
                          Отказаться
                        </div>
                        <Button
                          type="submit"
                          modifiers={
                            formik.values.phone.replace(/\D/g, "").length < 11
                              ? "-dark-gray -big -brd-rad-4"
                              : "-blue -txt-color-white -big -brd-rad-4"
                          }
                          label={"Запросить"}
                          disabled={formik.values.phone.replace(/\D/g, "").length < 11}
                        />
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
