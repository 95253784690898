import React from "react";
import { createRoot } from "react-dom/client";
import "./assets/css/app.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";

if (!process.env.REACT_APP_DEV) {
  Sentry.init({ dsn: "https://0f3d7ffee0d64eb4b452372e63066b2b@sentry.io/1850652" });
}

const root = createRoot(document.getElementById("root"));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
