import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const browserSlice = createSlice({
  name: "browser",
  initialState,
  reducers: {
    setBrowser: (state, action) => {
      return state = { ...action.payload };
    },
  },
});

export const { setBrowser } = browserSlice.actions;

export default browserSlice.reducer;
