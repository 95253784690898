import React from 'react'
import closeBtn from 'assets/images/demo/close.png'
import okImg from 'assets/images/demo/ok.png'

export default function RequestSent({ onChange, setSentStep }) {
  return (
    <>
      <div className='card__wrapper card__wrapper--demo'>
        <div className='card__layout'>
          <div className='demoRequest'>
            <div className='demoRequest__modal -sent'>
              <div className='skipTimer -close -min'>
                <img src={closeBtn} width='10' height='10'
                  // onClick={() => setSentStep(false)}
                  alt="close"
                  onClick={() => {
                    onChange()
                    setSentStep(false)
                  }}
                />
              </div>

              <div className='_img' >
                <img src={okImg} width='64' height='64' alt="demo_request" />
              </div>

              <div className='demoRequest__modal__body'>
                <div className='center'>
                  <div className='paragraph -common'>
                    <p>
                      Запрос на демонстрацию сервиса отправлен.
                    </p>
                    <p>
                      Скоро с Вами свяжется наш менеджер
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

