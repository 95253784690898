import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sumOrdersPrice: 0,
  sumOrdersNumber: 0,
  categories: [],
  selected_categories: [],
  keywords: [],
  tab: {
    table: "categoryPosition",
    chart: "general",
  },
};

export const cardInfoExternalSlice = createSlice({
  name: "cardInfoExternal",
  initialState,
  reducers: {
    setExternalOrders: (state, action) => {
      const { name, value } = action.payload;
      localStorage.setItem(name, value);
      state[name] = value;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setSelectedCategories: (state, action) => {
      state.selected_categories = action.payload;
    },
    setKeywords: (state, action) => {
      state.keywords = action.payload;
    },
    setSelectedKeywords: (state, action) => {
      state.selected_keywords = action.payload;
    },
    setProductCardTab: (state, action) => {
      const { name, value } = action.payload;
      state.tab[name] = value;
    },
  },
});

export const { setExternalOrders, setCategories, setSelectedCategories, setKeywords, setSelectedKeywords, setProductCardTab } =
  cardInfoExternalSlice.actions;

export default cardInfoExternalSlice.reducer;
