import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPaymentExist: localStorage.getItem("isPaymentExist") === "true" ? true : false,
  periods: [],
  chosenPeriod: "month",
  types: [],
  tariffs: {},
  chosenTariff: {},
  isShowPayment: false,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPriceDiscount: (state, action) => {
      const { name, value } = action.payload;
      localStorage.setItem(name, value);
      state[name] = value;
    },
    setTypes: (state, action) => {
      state.types = action.payload;
    },
    setTariffs: (state, action) => {
      state.tariffs = action.payload;
    },
    setPeriods: (state, action) => {
      state.periods = action.payload;
    },
    setChosenPeriod: (state, action) => {
      state.chosenPeriod = action.payload;
    },
    setChosenTariff: (state, action) => {
      state.chosenTariff = action.payload;
    },
    setShowPayment: (state, action) => {
      state.isShowPayment = action.payload;
    },
  },
});

export const { setPriceDiscount, setTypes, setTariffs, setPeriods, setChosenPeriod, setChosenTariff, setShowPayment } =
  paymentSlice.actions;

export default paymentSlice.reducer;
