import * as axios from "axios";
import { getValueFromCookie } from "../utils/getValueFromCookie";

const initialToken = getValueFromCookie("token");

const axiosInstanceGET = (token) =>
  axios.create({
    baseURL: `${process.env.REACT_APP_HOST}`,
    headers: {
      Authorization: `Bearer ${token || initialToken}`,
    },
  });

const axiosInstancePOST = (token, data = {}) =>
  axios.create({
    baseURL: `${process.env.REACT_APP_HOST}`,
    data: data,
    headers: {
      Authorization: `Bearer ${token || initialToken}`,
    },
  });

export const NotificationAPI = {
  getAllNotification(token) {
    return axiosInstanceGET(token)
      .get("notifications/notifications/")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err)
      });
  },
};

export const ReportsAPI = {
  getSearchBrands(token, data) {
    return axiosInstancePOST(token)
      .post("reports/search_brand/", { search: data })
      .then((response) => {
        return response;
      });
  },
};
