import LocalizedStrings from "localized-strings";

export const useLangStrings = (lang, ru, en) => {
  let strings = new LocalizedStrings({
    ru: ru,
    en: en,
  });
  if (lang) {
    strings.setLanguage(lang);
  }
  return strings;
};
