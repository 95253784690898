import React from "react";
import { useDispatch } from "react-redux";
import { deleteNotificationMock } from "../../../store/slices/notificationSlice";

const MockTemplate = (props) => {
  const dispatch = useDispatch();

  /*
   * Удаляем нотификации не с бэка
   * */
  const removeMockNotification = (id) => {
    const node = document.getElementById(`notification-${id}`);
    if (node) {
      node.classList.add("animated", "fadeOutDown");
    }

    function handleAnimationEnd() {
      node.classList.remove("animated", "fadeOutDown");
      node.removeEventListener("animationend", handleAnimationEnd);
      node.style.display = "none";
      dispatch(deleteNotificationMock(id));
      timerId && clearTimeout(timerId);
    }

    node && node.addEventListener("animationend", handleAnimationEnd);
  };
  let timerId = setTimeout(() => {
    removeMockNotification(props.id);
    clearTimeout(timerId);
  }, 5000);

  return (
    <>
      <div
        id={`notification-${props.id}`}
        onMouseEnter={() => {
          clearTimeout(timerId);
        }}
        onMouseLeave={() => {
          timerId = setTimeout(() => {
            removeMockNotification(props.id);
            clearTimeout(timerId);
          }, 5000);
        }}
        className={`notification__item animated fadeInUp ${props.error && "-error"}`}
      >
        <div className="notification__item__icon">
          {props.error ? (
            <i className="icon-error" />
          ) : props.attention ? (
            <i className={`icon-attention`} />
          ) : props.report ? (
            <i className={`icon-table`} />
          ) : (
            <i className={`icon-success`} />
          )}
        </div>
        <div className="notification__item__content">
          <p className="notification__item__title">{props.title}</p>
          {props.description && <p className="notification__item__text">{props.description}</p>}
          <div className="notification__item__buttons">
            {props.error && (
              <button
                type="button"
                className="-gray"
                onClick={() => {
                  window.location.reload();
                }}
              >
                {props.lang === "en" ? "Reload page" : "Обновить страницу"}
              </button>
            )}
          </div>
        </div>
        <button type="button" className="notification__item__close" onClick={(e) => removeMockNotification(props.id)}>
          <i className="icon-close" />
        </button>
      </div>
    </>
  );
};

export default MockTemplate;
