import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebar: false,
  dashboard: false,
  categoryAnalysis: false,
  externalProductCard: false,
  externalBrandCard: false,
  externalCategoryCard: false,
  seoOptimization: false,
};

export const onBoardingSlice = createSlice({
  name: "onBoarding",
  initialState,
  reducers: {
    showOnBoarding: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
});

export const { showOnBoarding } = onBoardingSlice.actions;

export default onBoardingSlice.reducer;
