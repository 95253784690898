import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  date: false,
  filterChecked: "week",
  dateForDynamicTab: {},
  brands: [],
  brands_chart_data: sessionStorage.getItem("brands_chart_data") ? JSON.parse(sessionStorage.getItem("brands_chart_data")) : [],
  brands_chart_chosen: sessionStorage.getItem("brands_chart_chosen")
    ? JSON.parse(sessionStorage.getItem("brands_chart_chosen"))
    : [],
  good_types: sessionStorage.getItem("good_types") ? JSON.parse(sessionStorage.getItem("good_types")) : [],
  brandsPage: {
    category: sessionStorage.getItem("brandsPage-category") ? JSON.parse(sessionStorage.getItem("brandsPage-category")) : "",
    sub_category: sessionStorage.getItem("brandsPage-sub_category") ? JSON.parse(sessionStorage.getItem("brandsPage-sub_category")) : "",
    subjects: sessionStorage.getItem("brandsPage-subjects") ? JSON.parse(sessionStorage.getItem("brandsPage-subjects")) : [],
    category_keys: sessionStorage.getItem("brandsPage-category_keys") ? JSON.parse(sessionStorage.getItem("brandsPage-category_keys")) : [],
    pageItemId: sessionStorage.getItem("brandsPage-pageItemId") ? JSON.parse(sessionStorage.getItem("brandsPage-pageItemId")) : '',
    price_lte: sessionStorage.getItem("brandsPage-price_lte") ? JSON.parse(sessionStorage.getItem("brandsPage-price_lte")) : '',
    price_gte: sessionStorage.getItem("brandsPage-price_gte") ? JSON.parse(sessionStorage.getItem("brandsPage-price_gte")) : '',
    delivery_date: sessionStorage.getItem("brandsPage-delivery_date") ? JSON.parse(sessionStorage.getItem("brandsPage-delivery_date")) : {},
  },
  suppliersPage: {
    category: sessionStorage.getItem("suppliersPage-category") ? JSON.parse(sessionStorage.getItem("suppliersPage-category")) : "",
    sub_category: sessionStorage.getItem("suppliersPage-sub_category") ? JSON.parse(sessionStorage.getItem("suppliersPage-sub_category")) : "",
    subjects: sessionStorage.getItem("suppliersPage-subjects") ? JSON.parse(sessionStorage.getItem("suppliersPage-subjects")) : [],
    category_keys: sessionStorage.getItem("suppliersPage-category_keys") ? JSON.parse(sessionStorage.getItem("suppliersPage-category_keys")) : [],
    pageItemId: sessionStorage.getItem("suppliersPage-pageItemId") ? JSON.parse(sessionStorage.getItem("suppliersPage-pageItemId")) : '',
    price_lte: sessionStorage.getItem("suppliersPage-price_lte") ? JSON.parse(sessionStorage.getItem("suppliersPage-price_lte")) : '',
    price_gte: sessionStorage.getItem("suppliersPage-price_gte") ? JSON.parse(sessionStorage.getItem("suppliersPage-price_gte")) : '',
    delivery_date: sessionStorage.getItem("suppliersPage-delivery_date") ? JSON.parse(sessionStorage.getItem("suppliersPage-delivery_date")) : {},
  },
  categoryPage: {
    category: sessionStorage.getItem("categoryPage-category") ? JSON.parse(sessionStorage.getItem("categoryPage-category")) : "",
    sub_category: sessionStorage.getItem("categoryPage-sub_category") ? JSON.parse(sessionStorage.getItem("categoryPage-sub_category")) : "",
    subjects: sessionStorage.getItem("categoryPage-subjects") ? JSON.parse(sessionStorage.getItem("categoryPage-subjects")) : [],
    category_keys: sessionStorage.getItem("categoryPage-category_keys") ? JSON.parse(sessionStorage.getItem("categoryPage-category_keys")) : [],
    pageItemId: sessionStorage.getItem("categoryPage-pageItemId") ? JSON.parse(sessionStorage.getItem("categoryPage-pageItemId")) : '',
    price_lte: sessionStorage.getItem("categoryPage-price_lte") ? JSON.parse(sessionStorage.getItem("categoryPage-price_lte")) : '',
    price_gte: sessionStorage.getItem("categoryPage-price_gte") ? JSON.parse(sessionStorage.getItem("categoryPage-price_gte")) : '',
    delivery_date: sessionStorage.getItem("categoryPage-delivery_date") ? JSON.parse(sessionStorage.getItem("categoryPage-delivery_date")) : {},
  },
  new_categories: [],
  new_brands: [],
  new_abc: [],
  new_statuses: sessionStorage.getItem("new_statuses") ? JSON.parse(sessionStorage.getItem("new_statuses")) : [],
  new_oblast: sessionStorage.getItem("new_oblast") ? JSON.parse(sessionStorage.getItem("new_oblast")) : [],
  new_warehouses: [],
  gender: sessionStorage.getItem("gender") ? JSON.parse(sessionStorage.getItem("gender")) : [],
  whs_type: sessionStorage.getItem("whs_type")
    ? JSON.parse(sessionStorage.getItem("whs_type"))
    : {
        value: 1,
        label: "FBO",
        is_active: true,
      },
  web_source: localStorage.getItem("new_ext_web_source")
    ? JSON.parse(localStorage.getItem("new_ext_web_source"))
    : { value: 1, label: "WildBerries", is_active: true },
  new_ext_web_source: localStorage.getItem("new_ext_web_source")
    ? JSON.parse(localStorage.getItem("new_ext_web_source"))
    : { value: 1, label: "WildBerries", is_active: true },
  filterDataForResponse: {},
  input_text: "",
  calendarIsActive: sessionStorage.getItem("calendarIsActive") ? sessionStorage.getItem("calendarIsActive") : false,
  lastReportData: localStorage.getItem("lastReportData") ? JSON.parse(localStorage.getItem("lastReportData")) : {},
  isLastReportData: false,
  category_card_setup: sessionStorage.getItem("category_card_setup")
    ? JSON.parse(sessionStorage.getItem("category_card_setup"))
    : {},
  loading: true,
  error: null,
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setSingleInside: (state, action) => {
      const { name, value, pageName } = action.payload;
      if (pageName) {
        sessionStorage.setItem(`${pageName}-${name}`, JSON.stringify(value));
        state[pageName][name] = value;
      } else {
        localStorage.setItem(name, JSON.stringify(value));
        state[name] = value;
      }
    },
    setSingleInsideLocal: (state, action) => {
      const { name, value } = action.payload;
      localStorage.setItem(name, JSON.stringify(value));
      state[name] = value;
    },
    setObjectKey: (state, action) => {
      const { name, value, property } = action.payload;
      sessionStorage.setItem(name, JSON.stringify({ ...state[name], [property]: value }));
      state[name][property] = value;
    },
    setDateForDynamicTab: (state, action) => {
      state.dateForDynamicTab = action.payload;
    },
    setCalendarActive: (state, action) => {
      sessionStorage.setItem("calendarIsActive", action.payload);
      state.calendarIsActive = action.payload;
    },
    changeWebSource: (state, action) => {
      const el = localStorage.getItem("all_web_sources")
        ? JSON.parse(localStorage.getItem("all_web_sources")).find((el) => el.value == action.payload)
        : { is_active: true, label: "WildBerries", value: 1 };
      localStorage.setItem("new_ext_web_source", JSON.stringify(el));
      state.new_ext_web_source = el;
    },
    setIsLastReportData: (state, action) => {
      state.isLastReportData = action.payload;
    },
  },
});

export const {
  setSingleInside,
  setSingleInsideLocal,
  setObjectKey,
  setDateForDynamicTab,
  setCalendarActive,
  changeWebSource,
  setIsLastReportData,
} = filterSlice.actions;

export default filterSlice.reducer;
