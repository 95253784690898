import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const SideTemplate = (props) => {
  const [display, setDisplay] = useState(true);
  const [animation, setAnimation] = useState(false);
  const [remove, setRemove] = useState(false);
  const { internal_event_types } = useSelector((state) => state.events);
  const { keys_list_for_events } = useSelector((state) => state.apiKey);

  const timeOut = useRef(null);
  const displayTimeOut = useRef(null);

  let strings = {
    was: "Было",
    now: "стало",
  };

  useEffect(() => {
    if (!display) {
      timeOut.current = setTimeout(() => setAnimation(true), 2000);
      displayTimeOut.current = setTimeout(() => setRemove(true), 3000);
    } else {
      clearTimeout(timeOut.current);
      clearTimeout(displayTimeOut.current);
    }
  }, [display]);
  useEffect(() => {
    return () => {
      clearTimeout(displayTimeOut.current);
      clearTimeout(timeOut.current);
    };
  }, []);

  const changeInfoTitle = (item) => {
    const str = item.replace(/"/g, "");
    let newStr = str.split(props.title);
    return [newStr[0], `«`, <span>{props.title}</span>, `»`, newStr[1]];
  };

  return (
    <div
      onMouseEnter={() => {
        setDisplay(true);
      }}
      onMouseLeave={() => {
        setDisplay(false);
      }}
      style={{ display: `${remove ? "none" : "flex"}` }}
      className={`notification__item animated fadeInUp ${animation ? "fadeOutDown" : ""}`}
    >
      <div className="notification__item__icon">
        {props.extra && props.extra.main_pic ? (
          <img src={props.extra.main_pic} alt="main_pic" />
        ) : (
          <i
            className={`icon-${
              props.type === "TARGET"
                ? "target"
                : props.type === "INFO"
                ? "success"
                : props.type === "PRESET_SCHEDULER"
                ? "table-time"
                : "table"
            }`}
          />
        )}
      </div>
      <div className="notification__item__content">
        {props.type === "INFO" && <span className="notification__item__body">{changeInfoTitle(props.description)}</span>}
        <p className="notification__item__title">
          <p>
            {props.extra && props.extra.event && (
              <>
                {props.title}
                {props.event_type == 1 ? (
                  <b> {props.extra.event.name} </b>
                ) : (
                  <b>
                    &nbsp; {internal_event_types.find((el) => el.value == props.extra.event.event_type).label.toLowerCase()} для
                    ключа "{keys_list_for_events.find((el) => el.value == props.extra.event.wb_data).label}"
                  </b>
                )}

                <br />
                {props.extra.event.web_source ? props.extra.event.web_source : props.extra.event.wb_data ? "WildBerries" : ""}
              </>
            )}
          </p>
        </p>
        {props.type === "TARGET" && props.event_type == 1 ? (
          <>
            <div
              className={`notification__item__body ${
                props.extra.diff_value ? (props.extra.diff_value > 0 ? "-green" : "-red") : ""
              }`}
            >
              {props.extra.diff_value && props.extra.value_new && props.extra?.value_old
                ? props.extra.diff_value + " %" + " / " + (props.extra.value_new - props.extra.value_old).toFixed(0) + " ₽"
                : ""}
            </div>
            <span className="notification__item__desc">
              {strings.was} {props.extra.value_old} ₽, {strings.now} {props.extra.value_new} ₽
            </span>
          </>
        ) : props.type === "TARGET" && (props.event_type == 2 || props.event_type == 3 || props.event_type == 4) ? (
          <p className="notification__item__text">{props.description}</p>
        ) : (
          <p className="notification__item__text">
            {props.type !== "INFO" && props.description.replace(".", ":")} {props.type !== "INFO" && props.email}
          </p>
        )}
        <div className="notification__item__buttons">
          {props.url_file ? (
            <a href={props.url_file} target="_blank">
              {props.text}
            </a>
          ) : null}
        </div>
      </div>
      <button
        type="button"
        className="notification__item__close"
        onClick={(e) => {
          setAnimation(true);
          setTimeout(() => setRemove(true), 1000);
        }}
      >
        <i className="icon-close" />
      </button>
    </div>
  );
};

export default SideTemplate;
