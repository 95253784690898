import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // карточка Поставщика
  supplyCard: {
    products: {
      defaultFilters: {}, // предустановленные фильтры по умолчанию
      filters: {}, // значения фильтров после изменений
      changedFilters: [], // названия всех фильтров, которые были изменены
      openedFilter: "", // модалка какого фильтра открыта
      activeSort: "", // примененная сортировка
      sortDirection: "", // направление сортировки asc/desc
    },
    brands: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
    categories: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
    positionByCategory: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
    positionByKeywords: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
    suppliers: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
  },
  // карточка Категории
  categoryCard: {
    products: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
    brands: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
    keywords: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
    suppliers: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
  },
  // карточка Бренда
  brandCard: {
    categories: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
    products: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
    positionByCategory: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
    positionByKeywords: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
    suppliers: {
      defaultFilters: {},
      filters: {},
      changedFilters: [],
      openedFilter: "",
      activeSort: "",
      sortDirection: "",
    },
  },
};

export const tableFiltersSlice = createSlice({
  name: "tableFilters",
  initialState,
  reducers: {
    setFiltersValue: (state, action) => {
      const { name, value, page, table } = action.payload;

      state[page][table][name] = value;
    },
    addChangedFilter: (state, action) => {
      const { value, page, table } = action.payload;

      if (!state[page][table].changedFilters.includes(value)) {
        state[page][table].changedFilters.push(value);
      }
    },
    deleteChangedFilter: (state, action) => {
      const { value, page, table } = action.payload;

      const filteredArr = state[page][table].changedFilters.filter((item) => item !== value);
      state[page][table].changedFilters = filteredArr;
    },
  },
});

export const { setFiltersValue, addChangedFilter, deleteChangedFilter } = tableFiltersSlice.actions;

export default tableFiltersSlice.reducer;
