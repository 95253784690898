import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  counter: 0,
  counter_type: "",
  loading_data: false,
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoadingData: (state, action) => {
      state.loading = action.payload;
    },
    setDataCounter: (state, action) => {
      state.counter = action.payload;
    },
    setDataCounterType: (state, action) => {
      state.counter_type = action.payload;
    },
    setLoadingDataForFilter: (state, action) => {
      state.loading_data = action.payload;
    },
  },
});

export const { setLoadingData, setDataCounter, setDataCounterType, setLoadingDataForFilter } = loadingSlice.actions;

export default loadingSlice.reducer;
