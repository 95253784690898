import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink, useLocation } from "react-router-dom";
import strings from "../pages/lang/contact_ru.json";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import Button from "../buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as MessageSvg } from "assets/images/svg/message.svg";
import { applyContactForm } from "../../store/slices/displayBannerSlice";

const Contact = () => {
  const display = useSelector((state) => state.displayBanner.contactForm);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isPartners, setIsPartners] = useState(false);

  const [thanksPage, setThanksPage] = useState(false);
  const [emailEr, setEmailEr] = useState();
  const [mesEr, setMesEr] = useState();

  useState(() => {
    if (location.pathname.includes("partners")) {
      setIsPartners(true);
    } else {
      setIsPartners(false);
    }
  }, []);

  const phoneNumberMask = ["+", "7", "(", /[9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

  /*
   * Проверяем заполнены и верно ли заполнены нужные нам поля и шлем на бэк, для отправки письма
   * */
  const formSubmit = () => {
    const input = document.querySelectorAll("*[required]");
    const field = document.querySelectorAll(".form__input");
    let query = {};
    let sendCheck = true;
    input.forEach((item) => {
      if (item.type === "textarea" && item.value.length === 0) {
        setMesEr(strings.write_message);
        sendCheck = false;
      }
      if (item.type === "textarea" && item.value.length > 0) {
        setMesEr("");
      }
      if (item.type === "email") {
        const rex = /^([a-z0-9_\-]+\.)*[a-z0-9_\-]+@([a-z0-9][a-z0-9\-]*[a-z0-9]\.)+[a-z]{2,4}$/i;
        if (item.value.length === 0) {
          setEmailEr(strings.write_email);
          sendCheck = false;
        }
        if (!rex.test(item.value)) {
          setEmailEr(strings.wrong_email);
          sendCheck = false;
        } else {
          setEmailEr();
        }
      }
    });
    if (sendCheck) {
      field.forEach((item) => {
        query = { ...query, [item.name]: item.value };
      });
      const sendMessage = async () => {
        try {
          const res = await axios.post(`${process.env.REACT_APP_HOST}main/feedback/`, { ...query });
          return await res;
        } catch (e) {
          console.log(e);
        }
      };
      sendMessage().then((res) => {
        setThanksPage(true);
      });
    }
  };

  useEffect(() => {
    if (!display) {
      setThanksPage(false);
    }
  }, [display]);
  const welcomeText = strings.welcome_text;
  return (
    <>
      {display && (
        <div className="main__contact-form">
          <div className="card__wrapper">
            <div className="card__layout">
              <div className="form__wrap contact">
                <i className="icon-close" onClick={() => dispatch(applyContactForm({ value: false }))} />
                <div className="contact__welcome">
                  {thanksPage && (
                    <div className="contact__welcome__thanks">
                      <MessageSvg />
                      <h3>{strings.thanks}</h3>
                      <p>{strings.manager_write}</p>
                      <Button
                        onClick={() => {
                          dispatch(applyContactForm({ value: false }));
                        }}
                        label={strings.continue}
                        modifiers="-bg-color-primary -txt-color-white -big"
                      />
                    </div>
                  )}
                  {!thanksPage && (
                    <>
                      <div className="contact__welcome__column">
                        <h3 className="form__header">{isPartners ? "Запрос на партнёрство" : strings.title}</h3>
                        <p className="contact__welcome__text">{welcomeText}</p>
                      </div>
                      <Formik
                        initialValues={{
                          name: "",
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().email(strings.wrong_email_short).required(strings.enter_email),
                          password: Yup.string().required(strings.enter_pass),
                        })}
                        render={({ setFieldValue }) => (
                          <div className="contact__form">
                            <div className="contact__form__group">
                              <label>{strings.name}</label>
                              <Field name="name" type="text" className="form__input" autoComplete="off" />
                              <ErrorMessage name="name" component="p" className="form__error" />
                            </div>
                            <div className="contact__form__group">
                              <label>{strings.company}</label>
                              <Field
                                name="company"
                                type="text"
                                className="form__input"
                                placeholder={strings.company_placeholder}
                                autoComplete="off"
                              />
                            </div>
                            <div className="contact__form__group">
                              <label>Email*</label>
                              <Field
                                name="email"
                                type="email"
                                className="form__input"
                                required
                                placeholder="Email"
                                autoComplete="off"
                              />
                              {emailEr && <p className="form__error">{emailEr}</p>}
                            </div>
                            <div className="contact__form__group">
                              <label>{strings.phone}</label>
                              <Field
                                name="phone"
                                render={({ field }) => (
                                  <MaskedInput
                                    {...field}
                                    mask={phoneNumberMask}
                                    id="phone"
                                    placeholder={strings.phone_placeholder}
                                    type="text"
                                    className="form__input"
                                    onChange={(e) => {
                                      return e.target.value;
                                    }}
                                  />
                                )}
                              />
                              <ErrorMessage name="name" component="p" className="form__error" />
                            </div>
                            <div className="contact__form__group">
                              <label>{strings.message}*</label>
                              <Field
                                name="msg"
                                type="textarea"
                                required
                                component="textarea"
                                className="form__input contact__form__input__message"
                                placeholder={strings.message_placeholder}
                                autoComplete="off"
                              />
                              {mesEr && <p className="form__error">{mesEr}</p>}
                            </div>
                            <div className="contact__form__policy">
                              <Button
                                onClick={() => formSubmit()}
                                label={strings.send}
                                modifiers="-bg-color-primary -txt-color-white -big"
                              />
                              <span>
                                {strings.policy}
                                <NavLink to="/policy/">{strings.policy2}</NavLink>
                              </span>
                            </div>
                          </div>
                        )}
                      ></Formik>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { Contact };
