import axios from "axios";
import { store } from "../store/createdStore";
import { getValueFromCookie } from "../utils/getValueFromCookie";
import { logout } from "../store/slices/authSlice";
import { setErrorValue } from "../store/slices/errorsSlice";

const BASE_URL = `${process.env.REACT_APP_HOST}`;

const PRODUCT_URLS = [
  "reports/by_campaigns/",
  "reports/by_products/",
  "reports/by_brands/",
  "reports/by_categories/",
  "reports/avg_data/",
];

export const patchRequest = async (ADDED_URL, PUT_DATA) => {
  const token = getValueFromCookie("token");
  const { status, data } = await axios
    .patch(`${BASE_URL}${ADDED_URL}`, PUT_DATA, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((e) => {
      if (e && e.response && e.response.status === 401) {
        store.dispatch(logout());
      }
    });
  if (status >= 200 && status < 300) {
    try {
      return data;
    } catch (Error) {
      return Error;
    }
  } else if (status === 204) {
    return "no result";
  } else {
    throw Error("Unsuccessful response code");
  }
};

export const putRequest = async (ADDED_URL, PUT_DATA) => {
  const token = getValueFromCookie("token");
  const { status, data } = await axios
    .put(`${BASE_URL}${ADDED_URL}`, PUT_DATA, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((e) => {
      if (e && e.response && e.response.status === 401) {
        store.dispatch(logout());
      }
    });
  if (status >= 200 && status < 300) {
    try {
      return data;
    } catch (Error) {
      return Error;
    }
  } else if (status === 204) {
    return "no result";
  } else {
    throw Error("Unsuccessful response code");
  }
};
export const deleteRequest = async (ADDED_URL) => {
  const token = getValueFromCookie("token");
  const { status, data } = await axios
    .delete(`${BASE_URL}${ADDED_URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch((e) => {
      if (e && e.response && e.response.status === 401) {
        store.dispatch(logout());
      }
    });
  if (status >= 200 && status < 300) {
    try {
      return data;
    } catch (Error) {
      return Error;
    }
  } else if (status === 204) {
    return "no result";
  } else {
    throw Error("Unsuccessful response code");
  }
};
export const getRequest = async (ADDED_URL) => {
  const token = getValueFromCookie("token");
  const res = await axios
    .get(`${BASE_URL}${ADDED_URL}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    })
  if (res.status >= 200 && res.status < 300) {
    return res.data;
  } else {
    if (res.status === 401) {
      store.dispatch(logout());
    }
    return res;
  }
};

let cancelToken;
export const postRequest = async (ADDED_URL, POST_DATA) => {
  const token = getValueFromCookie("token");
  if (typeof cancelToken != typeof undefined && PRODUCT_URLS.includes(ADDED_URL)) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  const res = await axios(`${process.env.REACT_APP_HOST}${ADDED_URL}`, {
    method: "post",
    data: POST_DATA,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelToken.token,
  }).catch((e) => {
    if (e && e.response && e.response.status === 401) {
      store.dispatch(logout());
    }
    if (e && e.response && e.response.status === 403) {
      store.dispatch(setErrorValue({ name: "noAccess", value: true }));
    }
  });
  if (res && res.status === 204) {
    return "no result";
  }
  if (res && res.status >= 200 && res.status < 300) {
    try {
      return res.data;
    } catch (Error) {
      return Error;
    }
  }
};
/* SOURCE LIST */
export const GET_SOURCE_LIST = () => {
  return getRequest("reports/web_sources/");
};
