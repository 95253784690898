import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  display: false,
  addMore: false,
  clearInputs: false,
  name: "",
  brandInp: "",
  api_key: {},
  internal_event_type: { value: "SALES_EVENT", label: "Продажи", is_active: true },
  web_source: sessionStorage.getItem("web_source") ? sessionStorage.getItem("web_source") : {},
  type: {},
  brands: sessionStorage.getItem("brands") ? JSON.parse(sessionStorage.getItem("brands")) : [],
  genders: sessionStorage.getItem("genders") ? JSON.parse(sessionStorage.getItem("genders")) : [],
  good_types: sessionStorage.getItem("good_types") ? JSON.parse(sessionStorage.getItem("good_types")) : [],
  category_keys: localStorage.getItem("category_keys") ? JSON.parse(localStorage.getItem("category_keys")) : [],
  error: {},
  change: 0,
  product: {},
  vendor_code: "",
};

export const createEventSlice = createSlice({
  name: "createEvent",
  initialState,
  reducers: {
    setEvent: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setEventClear: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setEventObj: (state, action) => {
      const { name, prop, value } = action.payload;
      state[name][prop] = value;
    },
  },
});

export const { setEvent, setEventClear, setEventObj } = createEventSlice.actions;

export default createEventSlice.reducer;
