import ru from "./lang/modal_internal_demo_ru.json";

const strings = ru;

export const pages = [
    {
        id: 1,
        header: strings.headers[0],
        description: strings.description[0],
    },
    {
        id: 2,
        header: strings.headers[1],
        description: strings.description[1],
    },
    {
        id: 3,
        header: strings.headers[2],
        description: strings.description[2],
    },
    {
        id: 4,
        header: strings.headers[3],
        description: strings.description[3],
    },
    {
        id: 5,
        header: strings.headers[4],
        description: strings.description[4],
    }
]
