const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  TYPE_POTENTIAL_SELLER: false,
  TYPE_ACTIVE_SELLER: false,
  TYPE_MANAGER: false,
};

const greetingsModalSlice = createSlice({
  name: "greetingsModal",
  initialState,
  reducers: {
    setCheckboxValues (state, action) {
      const { inputName } = action.payload;
      state.TYPE_POTENTIAL_SELLER = false;
      state.TYPE_ACTIVE_SELLER = false;
      state.TYPE_MANAGER = false;
      state[inputName] = !state[inputName];
    },
  },
});

export const { setCheckboxValues } = greetingsModalSlice.actions;

export default greetingsModalSlice.reducer;
