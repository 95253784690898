import React, { Suspense, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Notification from "components/notification/Notification";
import { history } from "./history";
import BrowserNotification from "components/notification/browser/browser";
import { UserAgentProvider } from "@quentin-sommer/react-useragent";
import ErrorBoundary from "components/errorBoundary";
import { CreateEvent } from "components/events/createEvent";
import { Contact } from "components/ui/Contact";
import ReactGA from "react-ga";
import ym from "react-yandex-metrika";
import Sidebar from "components/header/Sidebar";
import NoAccessModal from "components/modals/NoAccessModal";
import { SpinnerLoader } from "components/typography/SpinnerLoader";
import ServiceEstimateModal from "components/modals/ServiceEstimateModal";
import ThanksForEstimateModal from "components/modals/ThanksForEstimateModal";
import { requestMark } from "utils/requestMark";
import dayjs from "dayjs";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);
  const { serviceErrorCommon } = useSelector((state) => state.errors);
  const isAuthenticated = !!localStorage.getItem("user");
  const estimateTime = localStorage.getItem("estimate_time");
  const [showEstimateModal, setShowEstimateModal] = useState(false);
  const [thanksModal, setThanksModal] = useState(false);

  let jivoScript = document.createElement("script");
  jivoScript.src = "//code.jivo.ru/widget/D1Qc62AdJD";
  document.body.appendChild(jivoScript);

  useEffect(() => {
    let timeoutIds = [];
    if (isAuthenticated && !auth.user?.extra_json?.service_estimate_viewed) {
      timeoutIds.push(
        setTimeout(function run() {
          const now = dayjs(new Date());
          const diff = now.diff(dayjs(JSON.parse(estimateTime)), "minutes");
          if (diff >= 0) {
            setShowEstimateModal(() => true);
            requestMark("service_estimate");
            for (let i = 0; i < timeoutIds.length; i++) {
              clearTimeout(timeoutIds[i]);
            }
            timeoutIds = [];
          }
          if (diff < 0) {
            timeoutIds.push(setTimeout(run, 60000));
          }
        }, 1000)
      );
    }
  }, [auth.user?.extra_json?.service_estimate_viewed]);

  window.addEventListener("storage", function (event) {
    if (event.key === "estimate_time") {
      setShowEstimateModal(false);
    }
  });

  useEffect(() => {
    if (process.env.REACT_APP_DEV === "false") {
      ym("hit", rest.path);
      ReactGA.pageview(rest.path);
    }
  }, []);

  return (
    <React.Fragment>
      <Suspense
        fallback={
          <div className="wrapper__main-loader">
            <SpinnerLoader />
          </div>
        }
      >
        <UserAgentProvider ua={window.navigator.userAgent}>
          <div className="wrapper__app">
            {sessionStorage.setItem("path", history.location.pathname)}
            {isAuthenticated && auth.user ? (
              <>
                <ErrorBoundary>
                  <Sidebar />
                  <NoAccessModal />
                  {showEstimateModal && (
                    <ServiceEstimateModal setShowEstimateModal={setShowEstimateModal} setThanksModal={setThanksModal} />
                  )}
                  {thanksModal && <ThanksForEstimateModal onClose={() => setThanksModal(() => false)} />}
                  <div
                    className={`wrapper__content ${!!auth.sidebar && !serviceErrorCommon ? "-sidebar-open" : "-sidebar-close"}`}
                  >
                    <Notification />
                    <BrowserNotification />
                    <Contact />
                    <CreateEvent />
                    <Component {...rest} />
                  </div>
                </ErrorBoundary>
              </>
            ) : (
              <>
                {localStorage.clear()}
                <Navigate to="/login/" />
              </>
            )}
          </div>
        </UserAgentProvider>
      </Suspense>
    </React.Fragment>
  );
};

export default PrivateRoute;
