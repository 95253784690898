import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { UAContext } from "@quentin-sommer/react-useragent";
import LocalizedStrings from "localized-strings";
import { setBrowser } from "../../../store/slices/browserSlice";

const BrowserNotification = (props) => {
  let strings = new LocalizedStrings({
    ru: {
      text: "В вашей версии браузера сервис может работать некорректно. Мы рекомендуем использовать Chrome или Firefox.",
    },
    en: {
      text: "The service may not work correctly in your browser version. We recommend using Chrome or Firefox.",
    },
  });

  if (props.lang) {
    strings.setLanguage(props.lang);
  }

  const { parser } = useContext(UAContext);
  useEffect(() => {
    if (parser.getBrowser().name === "Chrome" && Number(parser.getBrowser().major) >= 65) {
      props.browser({ notification: true });
    }
    if (parser.getBrowser().name === "Firefox" && Number(parser.getBrowser().major) >= 65) {
      props.browser({ notification: true });
    }
    if (parser.getBrowser().name === "Safari" && Number(parser.getBrowser().major) >= 11) {
      props.browser({ notification: true });
    }
  }, []);
  return (
    <div className="notification__warning">
      {!props.browserNoti.notification ? (
        <div className="notification__warning__item animated fadeInDown -yellow">
          <div className="notification__warning__item__part">
            <i className="icon-attention" />
            <p>{strings.text}</p>
          </div>
          <div className="notification__warning__item__part">
            <button
              type="button"
              className="notification__warning__item__close"
              onClick={(e) => props.browser({ notification: true })}
            >
              <i className="icon-close" />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
const mapStateToProps = (state) => ({
  lang: state.auth.user && state.auth.user.lang ? state.auth.user.lang : "ru",
  browserNoti: state.browser,
});
const mapDispatchToProps = (dispatch) => {
  return {
    browser: (notification) => dispatch(setBrowser(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowserNotification);
