import React, { useState } from "react";
import Button from "../../buttons/Button";
import LocalizedStrings from "localized-strings";
import { useLang } from "../../../utils/useLang";
import { getValueFromCookie } from "../../../utils/getValueFromCookie";
import axios from "axios";

const Cookies = () => {
  const [agree, setAgree] = useState(false);

  const agreeWithCookie = () => {
    document.cookie = `cookie_agree=agree; max-age=2592000; path=/;`;
    if (getValueFromCookie("cookie_agree") === "agree") {
      setAgree(true);
    }
  };

  const lang = useLang();

  const strings = new LocalizedStrings({
    ru: {
      text: "Наш сайт использует куки для улучшения вашего пользовательского опыта.",
      text2: "Подробности в нашей",
      text3: "Политике конфиденциальности",
      confirm: "Принять условия",
    },
    en: {
      text: "Our site uses cookies to improve your user experience.",
      text2: "Details in our",
      text3: "privacy policy",
      confirm: "Accept the terms",
    },
  });

  if (lang) {
    strings.setLanguage(lang);
  }

  const handleAcceptBtn = async () => {
    agreeWithCookie();

    try {
      await axios.get(`${process.env.REACT_APP_HOST}services/event-log/?event=accept_cookies`);
    } catch (err) {
      console.log(err);
    }
  }

  const handleRejectBtn = async () => {
    agreeWithCookie();

    try {
      await axios.get(`${process.env.REACT_APP_HOST}services/event-log/?event=reject_cookies`);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="cookie__wrapper" style={{ display: agree ? "none" : "block" }}>
      <div className="notification__cookie">
        <p>
          {strings.text} {strings.text2}{" "}
          <a href="/policy/" target="_blank">
            {strings.text3}.
          </a>
        </p>
        <div className="notification__cookie-btns">
          <Button label={strings.confirm} onClick={handleAcceptBtn} modifiers="agree" />
          <Button onClick={handleRejectBtn} icon="icon-close" modifiers="close" />
        </div>
      </div>
    </div>
  );
};

export default Cookies;
