import React from "react";

const SimpleDisclaimer = ({ options }) => {
  return (
    <div className="disclaimer__message" style={options.style}>
      <p className="disclaimer__paragraph">{options.textOne}</p>
      {options.textTwo && <p className="disclaimer__paragraph">{options.textTwo}</p>}
    </div>
  );
};

export default SimpleDisclaimer;
