import axios from "axios";
import { getValueFromCookie } from "./getValueFromCookie";

export const requestMark = async (label) => {
  const token = getValueFromCookie("token");
  try {
    await axios.get(`${process.env.REACT_APP_HOST}services/event-log/?event=${label}`, token && {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    console.log(err);
  }
};
