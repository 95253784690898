import React from "react";

const Label = ({ title, children }) => {
  return (
    <div className="createEvent__input">
      <span className="createEvent__label">{title}</span>
      {children}
    </div>
  );
};

export { Label };
