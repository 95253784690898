import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceErrorIA: false,
  serviceErrorEA: false,
  serviceErrorCommon: false,
  connectionErrorApi: false,
  noAccess: false, // нет доступа - для отрисовки блока "Ой, у Вас еще нет доступа к этой функции"
  noAccessModal: false, // На данном тарифе для анализа вам доступен только период от ...
  noApiKeysWarning: false, // У вас нет API ключа
};

export const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    setErrorValue: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
});

export const { setErrorValue } = errorsSlice.actions;

export default errorsSlice.reducer;
