import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SingleSelect } from "@neurodynamics/mv-ui";
import { Label } from "./Label";
import { DotSlider } from "./DotSlider";
import strings from "../lang/labels_ru.json";
import { setEvent } from "../../../../store/slices/createEventSlice";

const ByInternalEvents = ({ isEventExist, setIsEventExist }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.createEvent);
  const { keys_list_for_events } = useSelector((state) => state.apiKey);
  const { internal_event_types, eventsListInternal } = useSelector((state) => state.events);

  useEffect(() => {
    dispatch(setEvent({ name: "api_key", value: keys_list_for_events[0] }));
  }, []);

  useEffect(() => {
    const arr = eventsListInternal.filter((item) => item.wb_data === state?.api_key?.value).map((item) => item.event_type);
    if (arr.includes(state.internal_event_type.value)) {
      setIsEventExist(true);
    } else {
      setIsEventExist(false);
    }
  }, [state.internal_event_type, eventsListInternal, state.api_key]);

  return (
    <div className="createEvent__body__main">
      <div className="createEvent__body__main__row">
        <Label title={strings.key_name}>
          <SingleSelect
            data={keys_list_for_events}
            initialState={keys_list_for_events[0]}
            onChange={(v) => {
              v && dispatch(setEvent({ name: "api_key", value: v }));
            }}
            isWide={true}
          />
        </Label>
      </div>

      <div className={`createEvent__body__main__row${isEventExist ? " -error" : ""}`}>
        <Label title={strings.internal_event_type}>
          <SingleSelect
            data={internal_event_types}
            initialState={state.internal_event_type}
            onChange={(v) => {
              v && dispatch(setEvent({ name: "internal_event_type", value: v }));
            }}
            isWide={true}
          />
        </Label>
        {isEventExist && (
          <div className="createEvent__body__main__error">
            На данный ключ у вас уже создано событие этого типа. Попробуйте поменять настройки фильтра или удалить прошлое событие
          </div>
        )}
      </div>

      <div className="createEvent__body__main__row">
        <Label title="Назначенный показатель">
          <DotSlider isEventExist={isEventExist} />
        </Label>
      </div>
    </div>
  );
};

export { ByInternalEvents };
