import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RequestWelcome from "./RequestWelcome";
import RequestSent from './RequestSent';
import RequestReminder from "./RequestReminder";
import { putRequest } from "../../../requestor/transport";
import axios from "axios";
import { getValueFromCookie } from "../../../utils/getValueFromCookie";

const RequestDemo = ({ isDisplay, onChange, setShow, setLight, setIsSentSuccess }) => {
  const { user } = useSelector(state => state.auth)
  const [sentStep, setSentStep] = useState(false);
  const [reminderStep, setReminderStep] = useState(false);
  const [demoViewed, setDemoViewed] = useState(true)

  const sendStatus = async () => {
    try {
      await putRequest('users/user_extra/', { extra_json: { ...user.extra_json, request_demo_viewed: true } })
      setDemoViewed(true)
    } catch (error) {
      console.log(error);
    }
  }
  
  const handleClose = async () => {
    sendStatus()
    
    if (!isDisplay) {   // если юзер сам открыл окно из панели, то не выводим информ напоминание
      setReminderStep(true)
    } else {
      onChange()
    }
  }

  const query = async () => {
    const token = getValueFromCookie("token");

    try {
      const res = await axios.get(`${process.env.REACT_APP_HOST}users/user_extra/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      );
      return await res.data;
    } catch (e) {
      console.log(e.message);
    }
  }

  useEffect(() => {
    query()
      .then(data => {
        const request_demo_viewed = data?.extra_json?.request_demo_viewed

        if (request_demo_viewed) {
          setDemoViewed(true)
        } else {
          setDemoViewed(false)    // первичный показ окна
        }
      });
  }, []);

  return (
    <>
      {(isDisplay && !sentStep && !reminderStep) && (
        <RequestWelcome
          setSentStep={setSentStep}
          handleClose={handleClose}
          isDisplay={isDisplay}
          sendStatus={sendStatus}
          setIsSentSuccess={setIsSentSuccess}
        />
      )}

      {(!demoViewed && !sentStep && !reminderStep) && (
        <RequestWelcome
          setSentStep={setSentStep}
          handleClose={handleClose}
          sendStatus={sendStatus}
        />
      )}

      {(sentStep) && (
        <RequestSent
          // handleClose={handleClose}
          setSentStep={setSentStep}
          onChange={onChange}
        />
      )}

      {(reminderStep) && (
        <RequestReminder
          setReminderStep={setReminderStep}
          handleClose={handleClose}
          onChange={onChange}
          setShow={setShow}
          setLight={setLight}
        />
      )}
    </>
  );
};

export default RequestDemo

