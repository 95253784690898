import React from "react";

const Button = ({ type, onClick, modifiers, active, disabled, icon, label, children }) => {
  return (
    <button
      type={type || "button"}
      onClick={onClick}
      className={`button ${modifiers ? modifiers : ""} ${active ? "-active" : ""}`}
      disabled={disabled}
    >
      {icon && <i className={`${icon}`} />}
      {label}
      {children}
    </button>
  );
};

export default Button;
