import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import strings from "./lang/labels_ru.json";
import { Main } from "./ui/Main";
import { ButtonGroup } from "./ui/ButtonGroup";
import { ByProduct } from "./ui/ByProduct";
import { ByInternalEvents } from "./ui/ByInternalEvents";
import { useLocation } from "react-router-dom";
import { setEvent } from "../../../store/slices/createEventSlice";
import { changeWebSource } from "../../../store/slices/filterSlice";
import axios from "axios";
import { getValueFromCookie } from "../../../utils/getValueFromCookie";

const CreateEvent = () => {
  const node = useRef();
  const { analytic_type } = useSelector((state) => state.events);
  const state = useSelector((state) => state.createEvent);
  const filter = useSelector((state) => state.filter);
  const events = useSelector((state) => state.events);
  const [isEventExist, setIsEventExist] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { display } = state;

  const displayModal = (e) => {
    if (display && node.current.contains(e.target)) {
      return;
    }
    dispatch(setEvent({ name: "display", value: false }));
  };

  useEffect(() => {
    if (analytic_type !== "internal_analytic") {
      setIsEventExist(false);
    }
  }, [analytic_type]);

  useEffect(() => {
    if (display) {
      document.addEventListener("mousedown", displayModal);
    } else {
      document.removeEventListener("mousedown", displayModal);
    }
    return () => {
      document.removeEventListener("mousedown", displayModal);
    };
  }, [display]);

  useEffect(() => {
    dispatch(setEvent({ name: "web_source", value: filter.new_ext_web_source }));
    if (Object.keys(events.chosenWebSource).length !== 0) {
      dispatch(changeWebSource(events.chosenWebSource.value));
      dispatch(
        setEvent({
          name: "web_source",
          value: {
            value: events.chosenWebSource.value,
            label: events.chosenWebSource.label,
            is_active: events.chosenWebSource.active,
          },
        })
      );
    }
  }, [display]);

  const requestMark = async (label) => {
    const token = getValueFromCookie("token");
    try {
      await axios.get(`${process.env.REACT_APP_HOST}services/event-log/?event=events_${label}`, token && {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {display && (
        <div className="card__wrapper">
          <div className="card__layout">
            <div className={`createEvent${location.pathname.includes("events") ? " createEvent--events" : ""}`} ref={node}>
              <div className="createEvent__body">
                <Main requestMark={(label) => requestMark(label)} />
                {analytic_type == "external_analytic" ? (
                  <ByProduct requestMark={(label) => requestMark(label)} />
                ) : analytic_type == "internal_analytic" ? (
                  <ByInternalEvents isEventExist={isEventExist} setIsEventExist={setIsEventExist} />
                ) : (
                  ""
                )}
              </div>
              <div className="createEvent__buttons">
                <ButtonGroup
                  cancelTitle={strings.cancel}
                  createTitle={strings.create_event}
                  createdLabel={strings.created}
                  eventLabel={strings.event}
                  disabled={isEventExist}
                  requestMark={(label) => requestMark(label)}
                />
              </div>
            </div>
          </div>{" "}
        </div>
      )}
    </>
  );
};

export { CreateEvent };
