import { getValueFromCookie } from "../../../utils/getValueFromCookie";
import { store } from "../../../store/createdStore";
import { logout, userRefresh } from "../../../store/slices/authSlice";
import { setLastNotifications, setNotificationsThunk } from "../../../store/slices/notificationSlice";
import { getRequest, postRequest } from "../../../requestor/transport";

// Проверяем подключен ли сокет
const token = getValueFromCookie("token");
let timeout;
let socketsErrorCount = 0;
export let ws = {};

const check = (ws) => {
  if (!ws || (ws.readyState === WebSocket.CLOSED && token)) socketConnect();
};

const postErrorToFrontEndLogs = (error) => {
  postRequest("services/front_end_logs/", error).then(() => {
    socketsErrorCount = 0;
  });
};

export const socketConnect = () => {
  ws = new WebSocket(`${process.env.REACT_APP_SOCKET}/notifications?token=${getValueFromCookie("token")}`);
  let connectInterval;

  // Записываем соединение в state и сбрасываем интервал при успешном подключении
  ws.onopen = () => {
    console.log("socket connect");
    timeout = 5000;
    clearTimeout(connectInterval);
  };

  // Log and reconnecting to socket when close
  ws.onclose = (e) => {
    console.log("socket close", e);
    if (e.code === 4001) {
      store.dispatch(logout());
      clearTimeout(connectInterval);
    } else {
      timeout = 5000;
      connectInterval = setTimeout(check(ws), Math.min(10000, timeout));
    }
  };

  // Log and close socket when error
  ws.onerror = (err) => {
    console.log("socket error", err);
    socketsErrorCount = socketsErrorCount + 1;

    // Send error if socket try to connect more then 3 times
    if (socketsErrorCount >= 4) {
      const createdError = {
        error_type: "WS Error",
        error_info: "WebSocket connection failed, try connect 4 times",
      };
      postErrorToFrontEndLogs(createdError);
    }

    ws.close();
  };

  // Dispatch action when get message
  ws.onmessage = (data) => {
    let obj = JSON.parse(data.data);
    if (obj.notification) {
      // Update notifications in Redux
      store.dispatch(setNotificationsThunk(token));
      store.dispatch(setLastNotifications(obj.notification));
    }
    // Обновление пользователя
    if (obj.type === "chat.message" && obj.action === "check_entitlements") {
      getRequest(`users/entitlements/`).then((res) => {
        if (res.web && Object.keys(res.web)?.length > 0) {
          store.dispatch(
            userRefresh({
              user: {
                tariff_name: res.web.tariff_name,
                tariff_type: res.web.tariff_type,
                is_recurrent: res.web.is_recurrent,
                date_end: res.web.date_end,
              },
            })
          );
        }
      });
    }
  };
};
