import React from "react";

/*
 * Props
 * description: текст
 * modifiers: определет положение треугольника в подсказке (-bottom -left)
 * */

const Prompt = ({ description, modifiers }) => {
  return (
    <div className={`disclaimer__message -brd-rad-4 ${modifiers ? modifiers : ""}`}>
      <p>{description}</p>
    </div>
  );
};

export default Prompt;
