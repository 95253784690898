import React, { Component } from "react";
import ErrorBoundaryHtml from "./errorBoundaryHtml";
import { postRequest } from "../../requestor/transport";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true }, () => {
      console.info(error, errorInfo);
    });
    const createdError = {
      error_type: error.toString(),
      error_info: errorInfo.componentStack,
    };
    postRequest("services/front_end_logs/", createdError);
  }
  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryHtml />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
