import { getValueFromCookie } from 'utils/getValueFromCookie';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const BASE_URL = `${process.env.REACT_APP_HOST}`;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const token = getValueFromCookie("token")
    token && headers.set('authorization', `Bearer ${token}`)
    return headers
  },
})

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery,
  endpoints: _ => ({}),
})


