import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NotificationAPI } from "../../middleware/api";
import { setNotificationOrders } from "./apikeySlice";

const initialState = {
  notification: [],
  not_read_count: 0,
  lastNotifications: [],
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setLastNotifications: (state, action) => {
      state.lastNotifications.push(action.payload);
    },
    setNotificationMock: (state, action) => {
      state.notification = [action.payload];
    },
    deleteNotificationMock: (state, action) => {
      state.notification = state.notification.filter((item) => item.id !== action.payload);
    },
    setNotificationNotReadCount: (state, action) => {
      state.not_read_count = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setNotificationsThunk.fulfilled, (state, action) => {
      state.notification = action.payload.results.filter((item) => item.type !== "IA_ORDERS");
      state.not_read_count = getNotReadNotificationCount(
        action.payload.results.filter(
          (item) =>
            (item.type === "TARGET" && (item.event_type === 1 || 2 || 3 || 4)) ||
            item.type === "REPORT_STATUS" ||
            item.type === "INFO" ||
            item.type === "PRESET_SCHEDULER"
        )
      );
    });
  },
});

const getNotReadNotificationCount = (notifications) => {
  return notifications.filter((element) => element.is_read === false).length;
};

export const setNotificationsThunk = createAsyncThunk("notification/fetchData", async (token, { dispatch, rejectWithValue }) => {
  try {
    const response = await NotificationAPI.getAllNotification(token);
    dispatch(setNotificationOrders(response.data.results.filter((item) => item.type === "IA_ORDERS")));
    return response.data;
  } catch (err) {
    return rejectWithValue([], err);
  }
});

export const { setNotification, setLastNotifications, setNotificationMock, deleteNotificationMock, setNotificationNotReadCount } =
  notificationSlice.actions;

export default notificationSlice.reducer;
