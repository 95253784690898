import React from "react";
import img from "assets/images/error.png";
import img_dark from "assets/images/errorDark.png";
import LocalizedStrings from "localized-strings";
import ru from "./lang/error_ru";
import en from "./lang/error_en";
import { useSelector } from "react-redux";

const ErrorBoundaryHtml = () => {
  const dark = useSelector((state) => (state.auth.user && state.auth.user.dark_theme ? state.auth.user.dark_theme : false));

  let strings = new LocalizedStrings({ ru, en });

  const lang = useSelector((state) => (state.auth.user && state.auth.user.lang ? state.auth.user.lang : "ru"));

  if (lang) {
    strings.setLanguage(lang);
  }

  return (
    <div className="error__body">
      <div className="error__container">
        <img src={dark && dark ? img_dark : img} alt="error" />
        <h1>{strings.title}</h1>
        <p>{strings.text1}</p>
        <p>{strings.text2}</p>
        <p>{strings.text3}</p>
        <p>{strings.text4}</p>
        <p>
          {strings.text5} <a href="mailto:support@marketvision.app">support@marketvision.app</a>.
        </p>
      </div>
    </div>
  );
};

export default ErrorBoundaryHtml;
