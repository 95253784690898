import React, { useEffect } from "react";
import { Label } from "./Label";
import { Input } from "./Input";
import strings from "../lang/labels_ru.json";
import { useDispatch, useSelector } from "react-redux";
import { Error } from "./Error";
import { SingleSelect } from "@neurodynamics/mv-ui";
import { setEvent } from "../../../../store/slices/createEventSlice";
import { setCurrentAnalytic } from "../../../../store/slices/eventsSlice";
import { changeWebSource, setSingleInside } from "../../../../store/slices/filterSlice";

const Main = ({ requestMark }) => {
  const { analytic_types, analytic_type } = useSelector((state) => state.events);
  const state = useSelector((state) => state.createEvent);
  const web_sources = useSelector((state) => state.filterData.web_sources);
  const dispatch = useDispatch();

  const web_sources_ia = web_sources.map((el) => {
    if (el.label != "WildBerries") {
      return { ...el, is_active: false };
    } else {
      return { ...el };
    }
  });

  useEffect(() => {
    if (!state.web_source.value) {
      dispatch(setEvent({ name: "web_source", value: web_sources.filter((item) => item.is_active)[0] }));
    }
  }, []);

  const onErrorHandler = () => {
    dispatch(setEvent({ name: "error", value: true }));
  };

  return (
    <div className="createEvent__body__main -first">
      {analytic_type == "external_analytic" && (
        <div className="createEvent__body__main__row">
          <Input
            selector="name"
            classActive="-active"
            errorClass={state.error && state.error.name ? "-error" : ""}
            onErrorHandler={onErrorHandler}
            placeholder={strings.create_event_placeholder}
            requestMark={(label) => requestMark(label)}
          />
          <Error condition={state.error.name} textError={strings.name_error} />
        </div>
      )}
      <div className="createEvent__body__main__row">
        <Label title={strings.analytic_header}>
          <SingleSelect
            data={analytic_types}
            initialState={analytic_types.find((e) => e.value == analytic_type)}
            onChange={(v) => {
              v && dispatch(setCurrentAnalytic({ name: "analytic_type", value: v.value }));
              v && dispatch(setEvent({ name: "web_source", value: web_sources.find((e) => e.label == "WildBerries") }));
              v && requestMark(`create_${v.value}`);
            }}
            isWide={true}
            isDisabled={state.isDisabled ? true : false}
          />
        </Label>
      </div>
      <div className="createEvent__body__main__row">
        <Label title={strings.source}>
          <SingleSelect
            data={analytic_type == "internal_analytic" ? web_sources_ia : web_sources}
            initialState={state.web_source}
            onChange={(v) => {
              v && dispatch(setEvent({ name: "web_source", value: v }));
              v && dispatch(changeWebSource(v.value));
              dispatch(setSingleInside({ name: "category", value: "" }));
              dispatch(setSingleInside({ name: "sub_category", value: "" }));
              dispatch(setSingleInside({ name: "category_keys", value: [] }));
              dispatch(setSingleInside({ name: "subjects", value: [] }));
              v && requestMark(`create_${v.label?.toLowerCase()}`);
            }}
            theme={"light"}
            isWide={true}
          />
        </Label>
      </div>
    </div>
  );
};

export { Main };
