import React, { useEffect, useRef, useState } from "react";
import demo1 from "assets/images/internalDemo/demo1.jpg"
import demo2 from "assets/images/internalDemo/demo2.jpg"
import demo3 from "assets/images/internalDemo/demo3.jpg"
import demo4 from "assets/images/internalDemo/demo4.jpg"
import demo5 from "assets/images/internalDemo/demo5.jpg"
import { pages } from "./index";
import ru from "./lang/modal_internal_demo_ru.json";
import { Button } from "@neurodynamics/mv-ui";

const ModalDemoInternal = ({ isDisplay, onChange }) => {
  const strings = ru;
  const node = useRef();

  const [show, setShow] = useState(isDisplay);
  const [activePage, setActive] = useState(1);
  const imgs = [demo1, demo2, demo3, demo4, demo5];

  const handleNext = () => {
    if (activePage < pages.length) {
      setActive(activePage + 1);
    }
  };

  const handlePrev = () => {
    setActive(activePage - 1);
  };

  const handleFinish = () => {
    handleClose();
  };

  const handleClose = () => {
    setShow(false);
    if (onChange) {
      onChange();
    }
  };

  const displayModal = (e) => {
    if (show && node.current.contains(e.target)) {
      return;
    }
    handleClose();
  };

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", displayModal);
    } else {
      document.removeEventListener("mousedown", displayModal);
    }
    return () => {
      document.removeEventListener("mousedown", displayModal);
    };
  }, [show]);

  return (
    <>
      {show && (
        <div className="card__wrapper">
          <div className="card__layout">
            <div className="modalDemoInternal">
              <div className="modalDemoInternal__modal" ref={node}>
                <button
                  type="button"
                  className="modalDemoInternal__btn-close"
                  onClick={handleClose}
                >
                  <i className="icon-close" />
                </button>
                <h2 className="modalDemoInternal__modal-header">Основные функции Внутренней аналитики</h2>
                <img src={imgs[activePage - 1]} width="600" height="450" alt="modalDemoInternal_modal" />
                <div className="modalDemoInternal__modal__body">
                  <h1>{pages[activePage - 1].header}</h1>
                  <p className="paragraph">{pages[activePage - 1].description}</p>
                </div>
                <div className="modalDemoInternal__modal__footer">
                  {activePage > 1 ? (
                    <Button
                      label={strings.back}
                      color={"gray"}
                      size={"m"}
                      onClick={() => handlePrev()}
                      theme={"light"}
                    />
                  ) : (
                    <Button
                      label={strings.skip}
                      color={"transparent"}
                      size={"m"}
                      onClick={() => handleFinish()}
                      theme={"light"}
                    />
                  )}
                  <div className="modalDemoInternal__counter">
                    {activePage}/{pages.length}
                  </div>
                  <div className="modalDemoInternal__buttons-group">
                    <Button
                      label={activePage !== pages.length ? strings.next : strings.finish}
                      color={"blue"}
                      size={"m"}
                      onClick={activePage !== pages.length ? () => handleNext() : () => handleFinish()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { ModalDemoInternal };
