import React from "react";

const SpinnerLoader = ({ modifiers }) => {
  return (
    <svg className="loaders__spinner" width="14px" height="14px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle
        className={`loaders__spinner__path ${modifiers}`}
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
  );
};

export { SpinnerLoader };
