import { lazy } from "react";

const Main = lazy(() => import("./Main"));
const Profile = lazy(() => import("./Profile"));
const Recovery = lazy(() => import("./Recovery"));
const Access = lazy(() => import("./Access"));
const DownloadReport = lazy(() => import("./DownloadReport"));
const Report = lazy(() => import("./Report"));
const AddApiKey = lazy(() => import("./AddApiKey"));
const InternalMain = lazy(() => import("./InternalMain"));
const Goods = lazy(() => import("./Goods"));
const Good = lazy(() => import("./Good"));
const Filters = lazy(() => import("./Filters"));
const Supplies = lazy(() => import("./Supplies"));
const Orders = lazy(() => import("./Orders"));
const Finance = lazy(() => import("./Finance"));
const WorkWithTables = lazy(() => import("./WorkWithTables"));

export {
  Report,
  DownloadReport,
  Access,
  Recovery,
  Profile,
  Main,
  AddApiKey,
  InternalMain,
  Goods,
  Good,
  Filters,
  Supplies,
  Orders,
  Finance,
  WorkWithTables,
};
