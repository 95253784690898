import React, { Suspense, useEffect } from "react";
import { connect } from "react-redux";
import Cookies from "components/notification/cookies";
import ErrorBoundary from "components/errorBoundary";
import { getValueFromCookie } from "../utils/getValueFromCookie";
import { Contact } from "components/ui/Contact";
import ReactGA from "react-ga";
import ym from "react-yandex-metrika";
import { SpinnerLoader } from "components/typography/SpinnerLoader";
import { useLocation } from "react-router-dom";

export const PublicRoute = ({ component: Component, ...rest }) => {
  let jivoScript = document.createElement("script");
  jivoScript.src = "//code.jivo.ru/widget/D1Qc62AdJD";
  document.body.appendChild(jivoScript);

  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_DEV === "false") {
      ym("hit", rest.path);
      ReactGA.pageview(rest.path);
    }
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return (
    <React.Fragment>
      <Suspense
        fallback={
          <div className="wrapper__main-loader">
            <SpinnerLoader />
          </div>
        }
      >
        <Contact />
        <div className="App">
          <ErrorBoundary>
            <div className="wrapper">
              <Component />
              {getValueFromCookie("cookie_agree") === "agree" ? null : <Cookies />}
            </div>
          </ErrorBoundary>
        </div>
      </Suspense>
    </React.Fragment>
  );
};

export default connect()(PublicRoute);
