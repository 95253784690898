import { configureStore } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { history } from "../routes/history";
import apikeySlice from "./slices/apikeySlice";
import authSlice from "./slices/authSlice";
import browserSlice from "./slices/browserSlice";
import displayBannerSlice from "./slices/displayBannerSlice";
import preferenceSlice from "./slices/preferenceSlice";
import cardInfoExternalSlice from "./slices/cardInfoExternalSlice";
import createEventSlice from "./slices/createEventSlice";
import eventsSlice from "./slices/eventsSlice";
import filterSlice from "./slices/filterSlice";
import tabsSlice from "./slices/tabsSlice";
import tableFiltersSlice from "./slices/tableFiltersSlice";
import seoOptimizationSlice from "./slices/seoOptimizationSlice";
import reportGenerateButtonSlice from "./slices/reportGenerateButtonSlice";
import paymentSlice from "./slices/paymentSlice";
import onBoardingSlice from "./slices/onBoardingSlice";
import loadingSlice from "./slices/loadingSlice";
import errorsSlice from "./slices/errorsSlice";
import notificationSlice from "./slices/notificationSlice";
import filterDataSlice from "./slices/filterDataSlice";
import { baseApi } from "requestor/api/baseApi";
import greetingsModalSlice from "./slices/greetingsModalSlice";

const rootReducer = (history) => ({
  router: connectRouter(history),
  apiKey: apikeySlice,
  auth: authSlice,
  browser: browserSlice,
  displayBanner: displayBannerSlice,
  preference: preferenceSlice,
  cardInfoExternal: cardInfoExternalSlice,
  createEvent: createEventSlice,
  events: eventsSlice,
  filter: filterSlice,
  tabs: tabsSlice,
  tableFilters: tableFiltersSlice,
  seoOptimization: seoOptimizationSlice,
  reportGenerateButton: reportGenerateButtonSlice,
  payment: paymentSlice,
  onBoarding: onBoardingSlice,
  loading: loadingSlice,
  errors: errorsSlice,
  notification: notificationSlice,
  filterData: filterDataSlice,
  greetingsModal: greetingsModalSlice,
  [baseApi.reducerPath]: baseApi.reducer
});

const preloadedState = {};
export const store = configureStore({
  reducer: rootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([routerMiddleware(history), baseApi.middleware]),
  preloadedState,
});
