import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ApikeyListDisclaimer from "./ApikeyListDisclaimer";
import img_WB from "assets/images/icons/img_WB.svg";
import { new_getFilterData } from "../../requestor/new_getFilterData";
import { setEventKey, setValid, setReady, setOwner, setInternalId } from "../../store/slices/apikeySlice";
import { setErrorValue } from "../../store/slices/errorsSlice";
import { useGetApikeysQuery } from "requestor/api/apikeysApi";

const ApikeyList = () => {
  const { data = [], error, isLoading } = useGetApikeysQuery();

  const location = useLocation();
  const { internal_id: apiKeyId, ready, valid, keys_list } = useSelector((state) => state.apiKey);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState({
    id: 0,
    indicator: false,
  });

  useEffect(() => {
    if (location.pathname.includes("internal") && keys_list.length) {
      if (apiKeyId) {
        keys_list.map((el) => {
          el.id == apiKeyId &&
            setActive({
              id: apiKeyId,
              indicator: true,
            });
        });
      } else {
        setActive({
          id: keys_list[0].id,
          indicator: true,
        });
        dispatch(setInternalId(keys_list[0].id));
        dispatch(setValid(keys_list[0].is_api_key_valid));
        dispatch(setReady(keys_list[0].is_ready));
        dispatch(setOwner(keys_list[0].is_owner));
        dispatch(setEventKey({ name: "description", value: keys_list[0].description }));
      }
    } else {
      setActive({
        id: 0,
        indicator: false,
      });
    }
  }, [apiKeyId, keys_list]);

  useEffect(() => {
    active.id && ready && valid && new_getFilterData(active.id);
  }, [active.id]);

  useEffect(() => {
    error?.status === 503 && dispatch(setErrorValue({ name: "serviceErrorCommon", value: true }));
  }, [error]);

  return (
    <>
      {!isLoading && (
        <>
          {data?.length > 0 && (
            <ul className="sidebar__chapter__list-sidebar">
              {data.map((item) => (
                <li
                  key={item.id}
                  className={`sidebar__chapter__list-item ${active.id == item.id && active.indicator ? "-active" : ""}`}
                  onClick={() => {
                    setActive({
                      id: item.id,
                      indicator: true,
                    });
                    dispatch(setInternalId(item.id));
                    dispatch(setValid(item.is_api_key_valid));
                    dispatch(setReady(item.is_ready));
                    dispatch(setOwner(item.is_owner));
                    dispatch(setEventKey({ name: "description", value: item.description }));

                    navigate("/internal/main");
                  }}
                >
                  <div className="block">
                    <div className="text">
                      <div className="logo" style={{ fontWeight: "bold" }}>
                        <img src={img_WB} alt="icon_WB" />
                      </div>
                      <div className="descr" style={item.is_owner ? { color: "black" } : { color: "red" }}>
                        {item.is_owner ? item.description : (item.supplier_name || 'Название не указано')}
                      </div>
                    </div>
                    <ApikeyListDisclaimer item={item} />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </>
  );
};

export default ApikeyList;
