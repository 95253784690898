import { useLang } from "../../utils/useLang";
import ru from "./lang/index_ru.json";
import en from "./lang/index_en.json";
import { useLangStrings } from "../../utils/useLangStrings";

const lang = useLang()
const strings = useLangStrings(lang, ru, en)

export const pages = [
    {
        id: 1,
        header: strings.headers[0],
        description: strings.description[0],
        secondDescription: strings.secondDescription[0],
        moreInfo: strings.links[0],
    },
    {
        id: 2,
        header: strings.headers[1],
        description: strings.description[1],
        secondDescription: strings.secondDescription[1],
        moreInfo: strings.links[1],
        link: '/docs/faq/report/'
    },
    {
        id: 3,
        header: strings.headers[2],
        description: strings.description[2],
        secondDescription: strings.secondDescription[2],
        moreInfo: strings.links[2],
        link: '/docs/faq/report/'
    },
    {
        id: 4,
        header: strings.headers[3],
        description: strings.description[3],
        secondDescription: strings.secondDescription[3],
        moreInfo: strings.links[3],
        link: '/docs/faq/report/'
    },
]
