import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accept: "-loading",
  acceptReportValue: "",
};

export const reportGenerateButtonSlice = createSlice({
  name: "reportGenerateButton",
  initialState,
  reducers: {
    setAccept: (state, action) => {
      state.accept = action.payload;
    },
    setAcceptReportValue: (state, action) => {
      state.acceptReportValue = action.payload;
    },
  },
});

export const { setAccept, setAcceptReportValue } = reportGenerateButtonSlice.actions;

export default reportGenerateButtonSlice.reducer;
