import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "../../../../requestor/transport";
import { Button } from "@neurodynamics/mv-ui";
import { Checkbox } from "@neurodynamics/mv-ui";
import { setEvent, setEventClear, setEventObj } from "../../../../store/slices/createEventSlice";
import { createNewEvent, createNewEventInternal } from "../../../../store/slices/eventsSlice";
import { changeWebSource } from "../../../../store/slices/filterSlice";
import { setNotificationMock } from "../../../../store/slices/notificationSlice";

const ButtonGroup = ({ createTitle, cancelTitle, eventLabel, createdLabel, disabled, requestMark }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.createEvent);
  const { isDisabled, diff_value, api_key, internal_event_type, name, product, web_source, vendor_code, clearInputs } = state;
  const [isExtraChecked, setIsExtraChecked] = useState(false);
  const filter = useSelector((state) => state.filter);
  const events = useSelector((state) => state.events);

  const handleError = () => {
    let predict;
    predict = { name, product, vendor_code };

    const errors = Object.entries(predict).filter(
      (item) => item[1].length === 0 || item[1] === 0 || (item[0] === "product" && !item[1].name)
    );
    if (errors.length > 0) {
      errors.map((item) => dispatch(setEventObj({ name: "error", prop: item[0], value: true })));
    } else {
      dispatch(setEvent({ name: "error", value: {} }));
    }
    return errors;
  };

  const handleCancel = () => {
dispatch(setEventClear({ name: "display", value: false }));
    requestMark && requestMark("create_cancel");
  };

  const handleAccept = () => {
    let data;
    let endpoint;

    if (events.analytic_type == "external_analytic" && handleError().length > 0) {
      return;
    }

    if (events.analytic_type == "external_analytic") {
      data = {
        name: name,
        event_type: "BY_PRODUCT",
        web_source: web_source.value,
        vendor_code: vendor_code,
      };
      endpoint = `events/events/`;
    } else if (events.analytic_type == "internal_analytic") {
      data = {
        wb_data: api_key.value,
        diff_value: diff_value,
        event_type: internal_event_type.value,
      };
      endpoint = `ia/wb/events/`;
    }

    postRequest(endpoint, data)
      .then((res) => {
        if (res.event_type == "BY_PRODUCT") {
          dispatch(createNewEvent(res));
          dispatch(
            setNotificationMock({
              id: res.id,
              notify_type: "REPORT_MOCK",
              description: `${eventLabel} «${res.name}» ${createdLabel}`,
            })
          );
        } else {
          dispatch(createNewEventInternal(res));
          dispatch(
            setNotificationMock({
              id: res.id,
              notify_type: "REPORT_MOCK",
              description: `${eventLabel} «${state.internal_event_type.label}» ${createdLabel}`,
            })
          );
        }
      })
      .then(() => {
        if (isExtraChecked) {
          dispatch(setEventClear({ name: "display", value: true }));
          dispatch(setEvent({ name: "web_source", value: filter.new_ext_web_source }));
          if (Object.keys(events.chosenWebSource).length !== 0) {
            dispatch(changeWebSource(events.chosenWebSource.value));
            dispatch(
              setEvent({
                name: "web_source",
                value: {
                  value: events.chosenWebSource.value,
                  label: events.chosenWebSource.label,
                  is_active: events.chosenWebSource.active,
                },
              })
            );
          }
          dispatch(setEvent({ name: "isDisabled", value: isDisabled }));
          dispatch(setEvent({ name: "api_key", value: api_key }));
          dispatch(setEvent({ name: "diff_value", value: diff_value }));
          dispatch(setEvent({ name: "addMore", value: true }));
          dispatch(setEvent({ name: "clearInputs", value: !clearInputs }));
        } else {
          dispatch(setEventClear({ name: "display", value: false }));
        }
        requestMark && requestMark("create_accept");
      });
    return;
  };
  return (
    <>
      <Checkbox
        disabled={disabled}
        checked={isExtraChecked}
        onChange={() => {
          setIsExtraChecked(!isExtraChecked);
          requestMark && requestMark("create_extra");
        }}
        label={"Добавить еще"}
      />
      <Button label={cancelTitle} size={"m"} color={"transparent"} onClick={handleCancel} theme={"light"} />
      <div className="create-event-btn">
        <Button label={createTitle} size={"m"} color={"blue"} onClick={handleAccept} disabled={disabled} />
      </div>
    </>
  );
};

export { ButtonGroup };
