import React from "react";
import AppRouter from "./routes/AppRouter";
import { Provider, ReactReduxContext } from "react-redux";
import { store } from "./store/createdStore";
import { BrowserRouter } from "react-router-dom";

export default function App() {
  return (
    <BrowserRouter>
      <Provider store={store} context={ReactReduxContext}>
        <AppRouter />
      </Provider>
    </BrowserRouter>
  );
}
