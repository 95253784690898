import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEvent } from "../../../../store/slices/createEventSlice";
import useDebounce from "../../../../utils/useDebounce";

const Input = ({ selector, classActive, errorClass, onErrorHandler, placeholder, requestMark }) => {
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const debounced = useDebounce(text, 500);
  const [local, setlocal] = useState({
    active: false,
  });
  const handleActive = () => setlocal((state) => ({ ...state, active: true }));
  const handleBlur = () => {
    setlocal((state) => ({ ...state, active: false }));
  };
  const { name, product, vendor_code, error, addMore, clearInputs } = useSelector((state) => state.createEvent);

  useEffect(() => {
    dispatch(setEvent({ name: selector, value: debounced }));
    requestMark && debounced && requestMark("create_ea_name");
  }, [debounced]);

  useEffect(() => {
    if (!name && product.name && vendor_code) {
      dispatch(setEvent({ name: selector, value: vendor_code }));
      dispatch(setEvent({ name: "error", value: { ...error, name: false } }));
      setText(vendor_code.trim());
    }
  }, [product.name]);

  useEffect(() => {
    if (addMore && !product.name) {
      setText("");
    }
  }, [addMore, clearInputs, product.name]);

  return (
    <input
      type="text"
      className={`createEvent__input__text ${local.active && classActive} ${errorClass}`}
      value={text}
      onChange={(e) => {
        onErrorHandler();
        setText(e.target.value);
      }}
      onBlur={() => handleBlur()}
      onFocus={() => handleActive()}
      placeholder={placeholder}
    />
  );
};

export { Input };
