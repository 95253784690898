import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  analytic_types: [
    { value: "external_analytic", label: "Внешняя", is_active: true },
    { value: "internal_analytic", label: "Внутренняя", is_active: true },
  ],
  internal_event_types: [
    { value: "SALES_EVENT", label: "Продажи", is_active: true },
    { value: "REFUNDS_EVENT", label: "Возвраты", is_active: true },
    { value: "STOCKS_EVENT", label: "Остатки", is_active: true },
  ],
  eventsList: [],
  eventsListInternal: [],
  removingEvent: null,
  creatingEvent: null,
  chosenWebSource: {},
  searchValue: "",
  fullTable: true,
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setCurrentAnalytic: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    getEventsListReducer: (state, action) => {
      state.eventsList = action.payload;
    },
    getEventsListInternalReducer: (state, action) => {
      state.eventsListInternal = action.payload.sort((a, b) => b.id - a.id);
    },
    createNewEvent: (state, action) => {
      state.eventsList.push(action.payload)
      state.eventsList.sort((a, b) => b.id - a.id);
    },
    createNewEventInternal: (state, action) => {
      state.eventsListInternal.push(action.payload)
      state.eventsListInternal.sort((a, b) => b.id - a.id);
    },
    showDeleteEventDialog: (state, action) => {
      state.removingEvent = action.payload;
    },
    deleteEvent: (state, action) => {
      const newEventsList = state.eventsList.filter((event) => event.id !== action.payload);
      state.eventsList = newEventsList;
      state.removingEvent = null;
    },
    deleteEventInternal: (state, action) => {
      const newEventsListInternal = state.eventsListInternal.filter((event) => event.id !== action.payload);
      state.eventsListInternal = newEventsListInternal;
      state.removingEvent = null;
    },
    cancelRemovingEvent: (state) => {
      state.removingEvent = null;
    },
    toggleIsActiveEvent: (state, action) => {
      const index = state.eventsList.findIndex((event) => event.id === action.payload);
      state.eventsList[index].is_active = !state.eventsList[index].is_active
    },
    toggleIsActiveEventInternal: (state, action) => {
      const index = state.eventsListInternal.findIndex((event) => event.id === action.payload);
      state.eventsListInternal[index].is_active = !state.eventsList[index].is_active
    },
    filterBySearchValue: (state, action) => {
      state.searchValue = action.payload
    },
    clearWebSource: (state) => {
      state.chosenWebSource = {}
    },
    filterByWebSource: (state, action) => {
      const { name, value } = action.payload
      state[name] = value
    }
  },
});

export const {
  setCurrentAnalytic,
  getEventsListReducer,
  getEventsListInternalReducer,
  createNewEvent,
  createNewEventInternal,
  showDeleteEventDialog,
  deleteEvent,
  deleteEventInternal,
  cancelRemovingEvent,
  toggleIsActiveEvent,
  toggleIsActiveEventInternal,
  filterBySearchValue,
  clearWebSource,
  filterByWebSource
} = eventsSlice.actions;

export default eventsSlice.reducer;
