import React from "react";
import { useDispatch } from "react-redux";
import icon from "assets/images/errors/notification-wb-error.svg";
import { deleteNotificationMock } from "../../../store/slices/notificationSlice";

const MockTemplateWB = (props) => {
  const dispatch = useDispatch();

  /*
   * Удаляем нотификации не с бэка
   * */
  const removeMockNotification = (id) => {
    const node = document.getElementById(`notification-${id}`);
    if (node) {
      node.classList.add("animated", "fadeOutDown");
    }

    function handleAnimationEnd() {
      node.classList.remove("animated", "fadeOutDown");
      node.removeEventListener("animationend", handleAnimationEnd);
      node.style.display = "none";
      dispatch(deleteNotificationMock(id));
      timerId && clearTimeout(timerId);
    }

    node && node.addEventListener("animationend", handleAnimationEnd);
  };
  let timerId = setTimeout(() => {
    removeMockNotification(props.id);
    clearTimeout(timerId);
  }, 10000);

  return (
    <>
      <div
        id={`notification-${props.id}`}
        onMouseEnter={() => {
          clearTimeout(timerId);
        }}
        onMouseLeave={() => {
          timerId = setTimeout(() => {
            removeMockNotification(props.id);
            clearTimeout(timerId);
          }, 5000);
        }}
        className={`notification__item notification__item--wb animated fadeInUp`}
      >
        <div className="notification__item__content">
          <img className="notification__item__img" src={icon} alt="notification_content" />

          <p className="notification__item__title">{props.title}</p>
          {props.description && <p className="notification__item__text">{props.description}</p>}
        </div>
        <button type="button" className="notification__item__close" onClick={(e) => removeMockNotification(props.id)}>
          <i className="icon-close" />
        </button>
      </div>
    </>
  );
};

export default MockTemplateWB;
