import React, { useState } from "react";
import imgWarn from "assets/images/icons/img_warn.svg";
import SimpleDisclaimer from "../notification/disclaimer/SimpleDisclaimer";


const ApikeyListDisclaimer = React.memo(({ item }) => {
  const [display, setDisplay] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const optionsDisclaimer = {
    textOne: "Ошибка подключения.",
    textTwo: "Проверьте API ключ",
    style: {
      whiteSpace: "normal",
      width: "180px",
      left: left,
      top: top,
      position: 'fixed'
    },
  };

  return (
    <div
      className="image disclaimer"
      onMouseEnter={(e) => {
        const el = e.target.getBoundingClientRect()
        setLeft(el.x)
        setTop(el.y + 30)
        setDisplay(true)}}
      onMouseLeave={() => setDisplay(false)}
      data-id={item.id}
      key={item.id}
    >
      <img src={imgWarn} alt="icon" hidden={item.is_api_key_valid} />
      {!item.is_api_key_valid && display ? <SimpleDisclaimer options={optionsDisclaimer} /> : null}
    </div>
  );
});

export default ApikeyListDisclaimer;
