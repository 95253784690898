import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

const history = createBrowserHistory();

if (process.env.REACT_APP_TAGMANAGER === "true") {
  history.listen((location) => {
    ReactGA.initialize("UA-183210664-1");
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname);
  });
}

export { history };
