import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import welcome from "assets/images/onboarding/welcome.jpg";
import onboarding1 from "assets/images/onboarding/onboarding1.jpg";
import onboarding2 from "assets/images/onboarding/onboarding2.jpg";
import onboarding3 from "assets/images/onboarding/onboarding3.jpg";
import { pages } from "./index";
import { useLang } from "../../utils/useLang";
import ru from "./lang/index_ru.json";
import en from "./lang/index_en.json";
import { putRequest } from "../../requestor/transport";
import { NavLink } from "react-router-dom";
import { useLangStrings } from "../../utils/useLangStrings";
import { Button } from "@neurodynamics/mv-ui";
import { userSetBoarding } from "../../store/slices/authSlice";

const OnBoarding = ({ isDisplay, onChange, showHint }) => {
  const lang = useLang();
  const strings = useLangStrings(lang, ru, en);
  const node = useRef();

  const [show, setShow] = useState(isDisplay);
  const [activePage, setActive] = useState(1);
  const imgs = [welcome, onboarding1, onboarding2, onboarding3];

  const dispatch = useDispatch();
  const boardingViewed = useSelector((state) => state.auth.user.boarding_viewed);

  const handleNext = () => {
    if (activePage < pages.length) {
      setActive(activePage + 1);
    }
  };

  const handleSkip = () => {
    if (showHint && boardingViewed === false) {
      showHint();
    }
    handleClose();
  };

  const handlePrev = () => {
    setActive(activePage - 1);
  };

  const handleFinish = () => {
    handleClose();
  };

  const handleClose = () => {
    if (isDisplay === undefined) {
      putRequest("users/user_extra/", { boarding_viewed: true });
      dispatch(userSetBoarding({ payload: true }));
    } else {
      setShow(false);
    }
    if (onChange) {
      onChange();
    }
  };

  const displayModal = (e) => {
    if (show && node.current.contains(e.target)) {
      return;
    }
    handleClose();
  };

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", displayModal);
    } else {
      document.removeEventListener("mousedown", displayModal);
    }
    return () => {
      document.removeEventListener("mousedown", displayModal);
    };
  }, [show]);

  return (
    <>
      {show && (
        <div className="card__wrapper">
          <div className="card__layout">
            <div className="onBoarding">
              <div className="onBoarding__modal" ref={node}>
                <img src={imgs[activePage - 1]} width="664" height="264" alt="onboarding_modal" />
                <div className="onBoarding__modal__body">
                  <p className="paragraph -small -gray">{strings.quick_guide}</p>
                  <h1>{pages[activePage - 1].header}</h1>
                  <p className="paragraph">{pages[activePage - 1].description}</p>
                  {pages[activePage - 1].secondDescription && pages[activePage - 1].secondDescription.length > 1 && (
                    <p className="paragraph">{pages[activePage - 1].secondDescription}</p>
                  )}
                  {pages[activePage - 1].link ? (
                    <NavLink className="link" to={pages[activePage - 1].link}>
                      {pages[activePage - 1].moreInfo}
                    </NavLink>
                  ) : pages[activePage - 1].moreInfo ? (
                    <p className="paragraph">{pages[activePage - 1].moreInfo}</p>
                  ) : null}
                </div>
                <div className="onBoarding__modal__footer">
                  {activePage > 1 ? (
                    <Button
                      label={strings.back}
                      color={"gray"}
                      size={"m"}
                      onClick={() => handlePrev()}
                      theme={"light"}
                    />
                  ) : (
                    <Button
                      label={strings.skip}
                      color={"transparent"}
                      size={"m"}
                      onClick={() => handleSkip()}
                      theme={"light"}
                    />
                  )}
                  <div className="onBoarding__counter">
                    {activePage}/{pages.length}
                  </div>
                  <div className="onBoarding__buttons-group">
                    <Button
                      label={activePage !== pages.length ? strings.next : strings.finish}
                      color={"blue"}
                      size={"m"}
                      onClick={activePage !== pages.length ? () => handleNext() : () => handleFinish()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { OnBoarding };
