import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { OnBoarding } from "../onBoarding/OnBoarding";
import Prompt from "../events/eventsList/Prompt";
import RequestDemo from "../pages/Demostration/RequestDemo";
import { useDispatch, useSelector } from "react-redux";
import { userViewed } from "../../store/slices/authSlice";
import axios from "axios";
import { getValueFromCookie } from "../../utils/getValueFromCookie";

const FaqWidget = () => {
  const [show, setShow] = useState(false);
  const [showDemoRequest, setShowDemoRequest] = useState(false);
  const [showBoard, setShowBoard] = useState(false);
  const [hint, setHint] = useState(false);
  const [light, setLight] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleClose = () => {
    setShowBoard(false);
  };

  const handleCloseDemo = () => {
    setShowDemoRequest(false);
    dispatch(userViewed({ request_demo_viewed: true }));
  };

  /* Скрываем диалог, при клике не по нему */
  const dialog = useRef();
  const hideDialog = (e) => {
    if (dialog && dialog.current.contains(e.target)) {
      return;
    }
    setShow(false);
    setShowBoard(false);
  };

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", hideDialog);
    } else {
      document.removeEventListener("mousedown", hideDialog);
    }
    return () => {
      document.removeEventListener("mousedown", hideDialog);
    };
  }, [show]);

  const showHint = () => {
    setHint(true);
    setTimeout(() => {
      setHint(false);
    }, 5000);
  };

  const requestMark = async (label) => {
    const token = getValueFromCookie("token");
    try {
      await axios.get(`${process.env.REACT_APP_HOST}services/event-log/?event=${label}`, token && {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div ref={dialog}>
      <div className={`sidebar__faq ${show ? "-is-active" : ""}`}>
        <button
          type="button"
          onClick={() => {
            setHint(false);
            setShow(!show);
            requestMark("faq_btn");
          }}
        >
          <i className="icon-union" />
        </button>
        {hint && <Prompt description={"Руководство для быстрого старта, вы сможете найти здесь."} modifiers={"-left"} />}
      </div>

      {show && (
        <div className="sidebar__faq__modal">
          <div
            className="faq-item"
            onClick={() => {
              setShow(false);
              setShowBoard(true);
              requestMark("faq_fast_start");
            }}
          >
            Быстрый старт
          </div>
          <NavLink
            className="faq-item"
            to="/docs/faq/report/"
            onClick={() => {
              requestMark("faq_work_with_service");
            }}
          >
            Работы с сервисом
          </NavLink>
          <NavLink
            className="faq-item"
            to="/docs/api/web_source/"
            onClick={() => {
              requestMark("faq_rest_api");
            }}
          >
            Rest API
          </NavLink>
          <div
            className={light ? "faq-item -light" : "faq-item"}
            onClick={() => {
              setShow(false);
              setShowDemoRequest(true);
              requestMark("faq_service_demo");
            }}
          >
            Демонстрация сервиса
          </div>
        </div>
      )}

      {showBoard && <OnBoarding onChange={handleClose} isDisplay={true} />}
      <OnBoarding showHint={showHint} />

      {showDemoRequest && <RequestDemo onChange={handleCloseDemo} isDisplay={true} setShow={setShow} setLight={setLight} />}

      <RequestDemo
        onChange={handleCloseDemo}
        setShow={setShow}
        setLight={setLight}
        // isDisplay={true}
      />
    </div>
  );
};

export { FaqWidget };
