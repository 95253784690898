import React from 'react';
import smileImg from "assets/images/icons/smile-angel.svg"
import { Button } from '@neurodynamics/mv-ui';

const ThanksForEstimateModal = ({ onClose }) => {
  return (
    <div className="modal-wrapper">
       <div className="service-estimate__modal">
       <i className="icon-close" onClick={onClose} />
       <div className="service-estimate__modal__content">
          <div className="service-estimate__modal__content__circle">
            <img src={smileImg} alt="image_comment" />
          </div>
          <h2>Спасибо за ваш отзыв!</h2>
          <Button label={"Готово"} color={"black"} onClick={onClose} size={"m"} />
          </div>
       </div>
    </div>
  );
};

export default ThanksForEstimateModal;
