import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import closeImg from "assets/images/newsvg/close.svg";
import errorImg from "assets/images/icons/error.svg";
import * as dayjs from "dayjs";
import "dayjs/locale/ru";
import { Button } from "@neurodynamics/mv-ui";
import { setErrorValue } from "../../store/slices/errorsSlice";

const NoAccessModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { noAccessModal } = useSelector((state) => state.errors);
  const { pathname } = useSelector((state) => state.router.location);
  const { tariff_type } = useSelector((state) => state.auth.user);

  const setDatePeriod = () => {
    let start = "";
    let end = "";

    if (pathname.includes("analysis/") && tariff_type === "demo") {
      start = dayjs().subtract(120, "day").format("DD.MM.YYYY");
      end = dayjs().subtract(90, "day").format("DD.MM.YYYY");
    } else {
      start = dayjs().subtract(97, "day").format("DD.MM.YYYY");
      end = dayjs().subtract(90, "day").format("DD.MM.YYYY");
    }
    return start + " - " + end;
  };

  const goToTariffs = () => {
    navigate("/payment");
    closeModal();
  };

  const closeModal = () => {
    dispatch(setErrorValue({ name: "noAccessModal", value: false }));
  };

  return (
    <>
      {noAccessModal && (
        <div className="error__external__wrapper">
          <div className="error__modal">
            <div className="error__close">
              <img src={closeImg} alt="error_close" onClick={() => closeModal()} />
            </div>
            <div className="error__round">
              <img src={errorImg} alt="error_round" />
            </div>
            <div className="error__center">
              {pathname !== "/events/" && pathname !== "/internal" ? (
                <h2>На данном тарифе для анализа вам доступен только период от {setDatePeriod()}</h2>
              ) : (
                <h2>На данном тарифе вам недоступен этот функционал</h2>
              )}
              {pathname !== "/events/" && pathname !== "/internal" ? (
                <p>
                  Для того, чтобы получить доступ ко всем периодам, вам нужно приобрести тариф: <br />
                  <b>Внешняя аналитика или PRO</b>
                </p>
              ) : (
                <p>
                  Для того, чтобы получить доступ к этому функционалу, вам нужно приобрести тариф: <br />
                  <b>{pathname === "/events/" ? "Внутренняя аналитика, Внешняя аналитика" : "Внутренняя аналитика"} или PRO</b>
                </p>
              )}
            </div>
            <Button color="blue" label="Купить" size={"l"} onClick={() => goToTariffs()} />
          </div>
        </div>
      )}
    </>
  );
};

export default NoAccessModal;
