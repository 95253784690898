import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  display: false,
  update: false,
  error: {},
  keys_list: [],
  keys_list_for_events: [],
  notification_orders: [],
  sectionPage: localStorage.getItem("sectionPage") ? localStorage.getItem("sectionPage") : "",
  section: "",
  finance_active_table: localStorage.getItem("finance_active_table") ?? "",
  internal_id: localStorage.getItem("internal_id") ? localStorage.getItem("internal_id") : "",
  description: localStorage.getItem("description") ? localStorage.getItem("description") : "",
  valid: localStorage.getItem("valid") ? localStorage.getItem("valid") : false,
  ready: localStorage.getItem("ready") ? localStorage.getItem("ready") : false,
  offline: false,
};

export const apikeySlice = createSlice({
  name: "apikey",
  initialState,
  reducers: {
    setEventKey: (state, action) => {
      const { name, value } = action.payload;
      localStorage.setItem(name, value);
      state[name] = value;
    },
    setKeyToList: (state, action) => {
      state.keys_list = action.payload;
    },
    setEventsKeyList: (state, action) => {
      state.keys_list_for_events = action.payload;
    },
    setNotificationOrders: (state, action) => {
      state.notification_orders = action.payload;
    },
    setValid: (state, action) => {
      localStorage.setItem("valid", action.payload);
      state.valid = action.payload;
    },
    setReady: (state, action) => {
      localStorage.setItem("ready", action.payload);
      state.ready = action.payload;
    },
    setOwner: (state, action) => {
      localStorage.setItem("isOwner", action.payload);
      state.owner = action.payload;
    },
    setSection: (state, action) => {
      const { name, value } = action.payload;
      localStorage.setItem(name, value);
      state[name] = value;
    },
    setSectionForGoods: (state, action) => {
      localStorage.setItem("section", action.payload);
      state.section = action.payload;
    },
    setInternalId: (state, action) => {
      localStorage.setItem("internal_id", action.payload);
      state.internal_id = action.payload;
    },
  },
});

export const {
  setEventKey,
  setKeyToList,
  setEventsKeyList,
  setNotificationOrders,
  setValid,
  setReady,
  setOwner,
  setSection,
  setSectionForGoods,
  setInternalId,
} = apikeySlice.actions;

export default apikeySlice.reducer;
