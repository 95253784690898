import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showMenu: false,
};

export const preferenceSlice = createSlice({
  name: "preference",
  initialState,
  reducers: {
    showMenu: (state, action) => {
      state.showMenu = action.payload;
    },
  },
});

export const { showMenu } = preferenceSlice.actions;

export default preferenceSlice.reducer;
