import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wbBanner: JSON.parse(localStorage.getItem("wbBanner")) ?? true,
  contactForm: false,
};

export const displayBannerSlice = createSlice({
  name: "displayBanner",
  initialState,
  reducers: {
    showBanner: (state, action) => {
      state[action.payload.banner] = action.payload.value;
      localStorage.setItem(action.payload.banner, action.payload.value)
    },
    applyContactForm: (state, action) => {
      state.contactForm = action.payload.value;
    },
  },
});

export const { showBanner, applyContactForm } = displayBannerSlice.actions;

export default displayBannerSlice.reducer;
