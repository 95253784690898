import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Lamoda from "../img/Lamoda.jpg";
import WildBerries from "../img/WildBerries.jpg";
import Ozon from "../img/Ozon.jpg";
import Asos from "../img/Asos.jpg";

const Hint = ({ label, hint1, hint2, requestMark }) => {
  const node = useRef();
  const [local, setLocal] = useState({
    display: false,
  });
  const { display } = local;
  const source = useSelector((state) => state.createEvent.web_source);
  const img = [WildBerries, Lamoda, Ozon, Asos];
  const displayModal = (e) => {
    if (display && node?.current?.contains(e.target)) {
      return;
    }
    setLocal((state) => ({ ...state, display: false }));
  };
  useEffect(() => {
    if (display) {
      document.addEventListener("mousedown", displayModal);
    } else {
      document.removeEventListener("mousedown", displayModal);
    }
    return () => {
      document.removeEventListener("mousedown", displayModal);
    };
  }, [display]);
  return (
    <div
      className="createEvent__hint"
      onClick={() => {
        setLocal((state) => ({ ...state, display: !display }));
        requestMark && requestMark("create_hint");
      }}
      ref={node}
    >
      <i className="icon-attention_circle" />
      <span className="createEvent__hint__label">{label}</span>
      {display && (
        <div className="createEvent__hint__modal">
          <span className="createEvent__hint__modal__hints">
            <span className="createEvent__hint__modal__num">1.</span>
            {hint1}
          </span>
          <span className="createEvent__hint__modal__hints">
            <span className="createEvent__hint__modal__num">2.</span>
            {hint2}
          </span>
          <img src={img.find((item) => item.includes(source.label))} alt="hint" />
        </div>
      )}
    </div>
  );
};

export { Hint };
