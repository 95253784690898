import React from "react";
import { Label } from "./Label";
import { Products } from "../filters/Products";
import strings from "../lang/labels_ru.json";
import { Hint } from "./Hint";

const ByProduct = ({ requestMark }) => {
  return (
    <div className="createEvent__body__main">
      <div className="createEvent__body__main__row">
        <Label title={strings.product_code}>
          <Products requestMark={(label) => requestMark(label)} />
          <Hint requestMark={(label) => requestMark(label)} label={strings.hint} hint1={strings.hint_1} hint2={strings.hint_2} />
        </Label>
      </div>
    </div>
  );
};

export { ByProduct };
